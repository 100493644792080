<template>
    <div>
     
      <b-row>
         <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h2 class="text-blue font-weight-bold mt-1 col-md-12">
            {{  $t("main_contact_person") }}
          </h2>
  
        </div>
        
      </b-row>
  
      <validation-observer ref="companyRegisterValidation" name="RegisterCompany">
        <b-form v-if="item && item.main_contacts_debtor && item.main_contacts_debtor.length>0">
          <b-card v-for="(item, index) in item.main_contacts_debtor" :key="index">
            <b-row m-0 p-0>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                  id="main-contact-gender-group"
                  :label="$t('salutation') + ':'"
                  label-for="main-contact-gender"
                >
                  <b-form-select
                    id="main-contact-gender"
                    v-model="item.gender"
                    disabled
                    :options="[
                      {
                        text: $t('select_one'),
                        value: null,
                        disabled: true,
                      },
                      { text: $t('mr'), value: '0' },
                      { text: $t('mrs'), value: '1' },
                    ]"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                  id="main-contact-email-group"
                  :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Number of Debtors"
                    rules="required|email"
                  >
                    <b-form-input
                      id="main-contact-email"
                      v-model="item.email"
                      required
                      disabled
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
            <b-row m-0 p-0>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                  id="main-contact-first-name-group"
                  :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First name"
                    rules="required"
                  >
                    <b-form-input
                      id="main-contact-first-name"
                      v-model="item.first_name"
                      required
                      :state="errors.length > 0 ? false : null"
                      disabled
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                  id="tel-group"
                  :label="$t('mobile') + ':'"
                  label-for="tel"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tel"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend id="phoneCountries">
                        <b-form-select
                          :disabled="true"
                          :options="phoneCountriesList"
                          v-model="phoneCountryCode"
                        >
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-tel"
                        v-model="item.tel"
                        @input="checkPhoneFormat"
                        disabled
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                      >
                      </b-form-input>
                    </b-input-group>
  
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="phoneValidation">{{
                      phoneValidation
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
            <b-row m-0 p-0>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                  id="main-contact-last-name-group"
                  :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      id="main-contact-last-name"
                      v-model="item.last_name"
                      required
                      :state="errors.length > 0 ? false : null"
                      disabled
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                  id="main-contact-position-group"
                  :label="$t('position_in_the_company') + ':'"
                  label-for="main-contact-position"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Position in the Company"
                    rules="required"
                  >
                    <b-form-input
                      id="main-contact-position"
                      v-model="item.position"
                      required
                      :state="errors.length > 0 ? false : null"
                      disabled
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
           
          </b-card>
          
        </b-form>
        <b-form v-else-if="item && item.main_contact && item.main_contact.id>0">
          <b-card>
            <b-row m-0 p-0>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                    id="main-person-gender-group"
                    :label="$t('salutation') + ':'"
                    label-for="main-contact-gender"
                >
                  <b-form-select
                      id="main-person-gender"
                      v-model="item.main_contact.gender"
                      disabled
                      :options="[
                      {
                        text: $t('select_one'),
                        value: null,
                        disabled: true,
                      },
                      { text: $t('mr'), value: '0' },
                      { text: $t('mrs'), value: '1' },
                    ]"
                      required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                    id="main-person-email-group"
                    :label="$t('company_email_address') + ':'"
                    label-for="main-contact-email"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Number of Debtors"
                      rules="required|email"
                  >
                    <b-form-input
                        id="main-person-email"
                        v-model="item.main_contact.email"
                        required
                        disabled
                        :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
            <b-row m-0 p-0>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                    id="main-person-first-name-group"
                    :label="$t('first_name') + ':'"
                    label-for="main-contact-first-name"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="First name"
                      rules="required"
                  >
                    <b-form-input
                        id="main-person-first-name"
                        v-model="item.main_contact.name"
                        required
                        :state="errors.length > 0 ? false : null"
                        disabled
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                    id="person-tel-group"
                    :label="$t('mobile') + ':'"
                    label-for="tel"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="tel"
                      rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend id="phoneCountries">
                        <b-form-select
                            :options="phoneCountriesList"
                            v-model="phoneCountryCode"
                            :disabled="true"
                        >
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                          id="main-person-tel"
                          v-model="item.main_contact.tel"
                          @input="checkPhoneFormat"
                          disabled
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                      >
                      </b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="phoneValidation">{{
                      phoneValidation
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
            <b-row m-0 p-0>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                    id="main-person-last-name-group"
                    :label="$t('last_name') + ':'"
                    label-for="main-contact-last-name"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                  >
                    <b-form-input
                        id="main-person-last-name"
                        v-model="item.main_contact.last_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        disabled
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group
                    id="main-person-position-group"
                    :label="$t('position_in_the_company') + ':'"
                    label-for="main-contact-position"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Position in the Company"
                      rules="required"
                  >
                    <b-form-input
                        id="main-person-position"
                        v-model="item.main_contact.position"
                        required
                        :state="errors.length > 0 ? false : null"
                        disabled
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>

          </b-card>

        </b-form>
      </validation-observer>
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, email } from "@validations";

  import Cleave from "vue-cleave-component";
  import { phone } from "phone";
  
  import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BAlert,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  // import { mapGetters } from 'vuex/types/helpers';
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BAlert,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      ValidationProvider,
      ValidationObserver,
      Cleave,
    },
    data() {
      return {
        currencies: [
          {
            value: '1', text: 'CHF'
          },
          {
            value: '2', text: 'EUR'
          }
        ],
        reason: null,
        showLoader: false,
        disableInputs: false,
        language: "",
        country_id: 1,
        countries: [],
        company_id: this.$store.state.auth.user.company_id,
        company_name: null,
        companies: [],
        company_zefix_data: null,
        company_address: null,
        company_address_nr: null,
        company_uid: null,
        company_zip: null,
        company_city: null,
        company_legal_form: null,
        company_website: null,
        buyer: 0,
        legal_forms: [
          {
            value: 4,
            text: this.$t("Gesellschaft mit beschränkter Haftung"),
          },
          {
            value: 3,
            text: this.$t("Aktiengesellschaft"),
          },
          {
            value: 0,
            text: this.$t("Einzelunternehmen"),
          },
        ],
        phoneSignatorValidation: "",
        company_established_at: null,
        number_of_employees: 0,
        annual_turnover: 0,
        selected_currency: "",
        intent_to_sell: "1% - 10%",
        number_of_debtors: "1-10",
        debt_enforcement: "Yes",
        intent_to_sell_values: [
          "1% - 10%",
          "11% - 20%",
          "21% - 30%",
          "31% - 50%",
          "51% - 70%",
          "71% - 80%",
          "81% - 90%",
          "91% - 100%",
        ],
        number_of_debtors_values: [
          "1-10",
          "11-20",
          "21-50",
          "51-100",
          "101-200",
          "201-500",
          "500+",
        ],
        debt_enforcement_values: ["Yes", "No"],
        main_contact_id: 0,
        main_contact_gender: "",
        main_contact_email: "",
        main_contact_first_name: "",
        main_contact_last_name: "",
        emailValidationsError: "",
        main_contact_tel: null,
        main_contact_position: null,
        signing_authority_type: "Collectively by two",
        signing_authority_type_values: [
          this.$t("Sole signatory"),
          this.$t("Collectively by two"),
        ],
        phoneContries: "CH",
        phoneValidation: "",
        signators: [
          {
            iso_code: null,
            gender: "Mr",
            first_name: null,
            email: null,
            last_name: null,
            tel: null,
            position: null,
            signer: {
              signing_authority_type: "Collectively by two",
            },
          },
        ],
        role: "",
        terms_and_conditions: "",
        data_privacy: "",
        deletedSignators: "",
        company: {
          status: 99,
          score: 0,
        },
        bank_name: "",
        bank_address: "",
        iban: "",
        boxTitle: "",
        reference_number: "",
        phoneOptions: {
          phone: {
            delimiters: [" ", " ", " "],
            blocks: [2, 3, 2, 2],
          },
        },
        phoneCountriesList: [],
        item: null
      };
    },
    name: "CompanyUpdateForm",
  
    created() {
      this.getData();
    },
    props: ["makeCall"],
    computed: {
    },
    watch: {
      makeCall(newValue, oldValue) {
        if (newValue !== oldValue && this.makeCall) {
          this.getData();
        }
      },
    },
    methods: {
    
      checkPhoneFormat() {
        let validation = phone(this.main_contact_tel, {
          country: this.phoneContries,
          validateMobilePrefix: false
        });
  
        if (validation && validation.isValid == false) {
          this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
        } else {
          this.phoneValidation = "";
        }
      },
  
      getData() {
        this.$http
          .post(`/company/admin/show/${this.$route.params.id}`)
          .then((res) => {
            if (res && res.data && res.data.value) {
                let response = res.data.value;
              this.item = res.data.value;
  
              this.role = response.role;
              this.buyer = response.buyer;

              if(this.item && this.item.main_contacts_debtor && this.item.main_contacts_debtor.length > 0) {
                this.getUserPhoneCode(this.item.main_contacts_debtor[0].user_id);
              } else if(this.item && this.item.main_contact && this.item.main_contact.id > 0) {
                  this.getUserPhoneCode(this.item.main_contact.id);
              }
            }
          });
      },

      getUserPhoneCode(user_id) {
        this.$http
          .post(`/user/admin/getPhoneCode/${user_id}`)
          .then((res) => {
            if (res && res.data && res.data.value) {
              this.phoneCountriesList.push({
                  value: res.data.value.code,
                  text: res.data.value.code,
              });
              this.phoneCountryCode = res.data.value.code;
            }
          });
      }
    },
  };
  </script>
  
  <style scoped>
  </style>
  