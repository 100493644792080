var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","hide-header-close":"","id":"modal-center","title":_vm.singleFile && _vm.singleFile.title ? _vm.singleFile.title : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("new_debtor"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[_c('b-form',{on:{"reset":_vm.onReset}},[_c('div',{staticClass:"col-md-12"},[_c('b-row',[_c('b-col',[_c('b',[_vm._v(" "+_vm._s(_vm.$t("invoice_id"))+": I-"+_vm._s(_vm.invoiceId)+" ")])])],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"id":"deptor","label":_vm.$t('deptor') + ':',"label-for":"deptor"}},[_c('validation-provider',{attrs:{"name":"debtor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.companies,"value-field":"id","text-field":"name"},model:{value:(_vm.debtor),callback:function ($$v) {_vm.debtor=$$v},expression:"debtor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"id":"payment_date","label":_vm.$t('payment_date') + ':',"label-for":"payment_date"}},[_c('validation-provider',{attrs:{"name":"payment_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"no-flip":"","id":"payment_date","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs,"required":""},on:{"input":_vm.changeDueDate},model:{value:(_vm.payment_date),callback:function ($$v) {_vm.payment_date=$$v},expression:"payment_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"id":"due_date","label":_vm.$t('due_date') + ':',"label-for":"due_date"}},[_c('validation-provider',{attrs:{"name":"due_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"due_date","no-flip":"","min":_vm.due_date,"state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs,"required":""},model:{value:(_vm.due_date),callback:function ($$v) {_vm.due_date=$$v},expression:"due_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"id":"amount","label":_vm.$t('amount') + ':',"label-for":"amount"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","type":"number","required":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[(_vm.showLoader)?_c('img',{attrs:{"src":"/loader.svg"}}):_vm._e()])],1)],1)])],1)],1),_c('AddNewCompnayModal',{attrs:{"file":_vm.file,"showDialog":_vm.addNewComp},on:{"closeAddCompModal":_vm.closeAddCompModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }