<template>
  <div>
    <div>
      <b-card>
        <b-col cols="9">
          <h2 class="text-blue font-weight-bold">
            {{ $t("additional_info") }}
          </h2>
        </b-col>
      </b-card>
      <b-card no-body>
        <div class="row col-md-12 mt-2">
          <h2 class="text-blue font-weight-bold col-md-12 ml-1">
            {{ $t("scoring") }}
          </h2>
        </div>

        <Scoring3 :id="id" @getAll="getScoringHistory"    :additional="'additional'" @redirectToScoring="redirectToScoring"/>
      </b-card>
      <b-card no-body>
        <div class="row col-md-12 mt-2">
          <h2 class="text-blue font-weight-bold col-md-12 ml-1">
            {{ $t("contacts") }}
          </h2>
        </div>

        <Table
          :url="`/company/admin/show/${$route.params.id}/contacts`"
          :body="{}"
          :updateData="makeCall"
          :showSearch="false"
          :fields="fields"
          :page="'additional_information'"
          show
        >
          <template #cell(gender)="data">
            <span v-html="data.item.gender == 0 ? $t('mr') : $t('mrs')"> </span>
          </template>

          <template #cell(status)="data">
            <span class="text-success" v-if="data.value == 1">{{
              $t("active")
            }}</span>
            <span v-if="data.value == 0">{{ $t("inactive") }}</span>
          </template>
        </Table>
      </b-card>
    </div>
  </div>
</template>
<script>
import Table from "../../Common/Table2.vue";
import Scoring3 from "../../ScoringForms/Scoring3.vue";
export default {
  components: { Table, Scoring3 },
  data() {
    return {
      loader: false,
      contacts: [],
      url: "",
      fields: [
        {
          label: "first_name",
          align: "start",
          key: "first_name",
          value: "first_name",
        },
        {
          label: "last_name",
          align: "start",
          key: "last_name",
          value: "last_name",
        },
        {
          label: "email",
          align: "start",
          key: "email",
          value: "email",
        },
        {
          label: "position",
          key: "position",
        },
        {
          label: "gender",
          key: "gender",
          customSlot: true,
          sortable: false,
        },
        {
          label: "tel",
          key: "tel",

          value: "tel",
        },
        {
          label: "status",
          key: "status",
          customSlot: true,
          sortable: false,
          value: "status",
        },
      ],
      id: "",
      history: [],
    };
  },

  created() {
    this.getScoringHistory();
  },
  props: ["companyData", "data"],
  watch: {
    makeCall(newValue, oldValue) {
      if (newValue !== oldValue && this.makeCall) {
        this.loader = true;
        this.loader = false;
      }
    },
  },
  methods: {
    redirectToScoring(){
      this.$emit('redirectToScoring');
    },
    getScoringHistory() {
      this.loader = true;
      this.id = this.$route.params.id;
      this.url = `/company/admin/scoring-history/${this.id}`;
      this.$http
        .post(`/company/admin/scoring-history/${this.$route.params.id}`)
        .catch((err) => {
          this.loader = false;
        })
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.history = res.data.value;
            this.loader = false;
          }
        });
    },
    makeCall() {
      this.getContacts();
    },

    getContacts() {
      this.$http
        .post(`/company/admin/show/${this.$route.params.id}/contacts`)
        .then((res) => {
          if (res) {
            this.contacts = res.data.value.data;
          }
        });
    },
  },
};
</script>
