<template>
  <b-alert :variant="scoringVariant(score)" show>
    <b-row>
      <div class="col-md-12 row">
        <div class="pt-2 col-xl-6 col-lg-6 col-md-12">
          <div class="row col-md-12">
            <h4 class="ml-1 mr-1">{{ data.name }}</h4>
            <span v-if="data && data.role == 'debtor'">
              <feather-icon
                icon="CodesandboxIcon"
                class="text-primary"
                size="20"
              ></feather-icon>
            </span>
            <span v-else-if="data && data.role == null || data.role == 'seller'">
              <feather-icon
                icon="CodesandboxIcon"
                class="text-secondary"
                size="20"
              ></feather-icon>
            </span>
            <span v-if="data && data.buyer == 1">
              <feather-icon
                icon="CodesandboxIcon"
                class="text-success"
                variant="success"
                size="20"
              ></feather-icon>
            </span>
          </div>
        </div>
        <div class="text-right pt-1 col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <b-button
            variant="primary"
            :disabled="nextStepStatus == 404"
            class="mr-1"
            @click="$emit('getNextStep')"
            >{{ $t("go_to_next_step") }}
          </b-button>

          <!-- <b-button @click="$emit('completeTask')" :disabled="nextStepStatus == 404" variant="primary">{{
            $t("complete")
          }}</b-button> -->
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>

      <div class="col-md-12 row">
        <b-col cols="12">
          <div class="row justify-content-between">
            <h4 align="start" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div class="col-md-12">
                <ScoringStatus :scoring="score" />
                <span v-if="score && score.length > 0">
                  ({{ score[0].score }}) {{ score[0].decision }}
                </span>
                <span v-else>{{ $t("no_scoring_registered") }}</span>
              </div>
            </h4>
            <h4 class="col-xl-6 col-lg-6 col-md-12 col-sm-12" align="end">
              <b-badge class="mb-1"> Next Step: <br class="mobile"> {{ nextStepText }}</b-badge>
            </h4>
          </div>
        </b-col>
      </div>
    </b-row>
  </b-alert>
</template>

<script>
import { BAlert, BRow, BCol, BButton } from "bootstrap-vue";
import ScoringStatus from "@/components/Company/ScoringStatus";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: { ScoringStatus, BAlert, BRow, BCol, BButton },
  name: "ScoringAlert",
  props: [
    "scoring",
    "data",
    "nextStepText",
    "role",
    "nextStep",
    "nextStepStatus",
  ],
  data() {
    return {
      score: [],
    };
  },
  created() {
    this.score = this.scoring;
  },
  methods: {
    performScoring1() {
      this.$http
        .post(`/company/admin/scoring/1/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.score = res.data.value;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(res.data.message),
                icon: "SuccessIcon",
                variant: "success",
              },
            });
          }
        });
    },
    goToFunction() {
      this.$http
        .post(`/nextStep/changeStatus/${this.nextStep.id}`)
        .then((res) => {
        });
    },
    scoringVariant(score) {
      if (score && score.length <= 0) {
        return "info";
      }
      if (
        score &&
        score.length > 0 &&
        (score[0].decision == "REJECTED" || score[0].decision == "REJECT")
      ) {
        return "danger";
      }
      if (
        score &&
        score.length > 0 &&
        (score[0].decision == "APPROVE" ||
          score[0].decision == "PRE-APPROVE" ||
          score[0].decision == "NEXT")
      ) {
        return "success";
      }
      if (
        score &&
        score.length > 0 &&
        (score[0].decision == "TBD forced" || score[0].decision == "TBD")
      ) {
        return "warning";
      }
    },
  },
};
</script>