<template>
  <b-card v-if="item">
    <div v-if="!auditTrial" class="">
      <div class="row justify-content-between mr-0 mb-2">
        <div class="col-md-6">
          <h3 class="font-weight-bolder">
            <b-link class="btn btn-outline-primary" @click="$emit('showTable')">
              <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
              {{ $t("back") }}
            </b-link>
            {{ $t("invoice_id") }} : I-{{ item.id }}
          </h3>
        </div>
        <div align="end">
          <InvoiceStatus :status="item.status" :invoice="item"/>
        </div>
      </div>
      <!-- <div class="" v-if="mainAlert.showAlert">
        <div
          class="col-md-12"
          :class="'alert alert-' + mainAlert.color + ' p-2'"
        >
          <div class="m-0 row">
            <div class="col-md-8 col-xl-8 col-sm-12 col-xs-12">
              <h4 class="text-primary">
                <b-avatar :variant="'light-' + mainAlert.color" size="45">
                  <feather-icon size="21" :icon="mainAlert.icon" />
                </b-avatar>
                <span
                  class="font-weight-bolder font-lg mt-1"
                  :class="'text-' + mainAlert.color"
                >
                  {{ mainAlert.message }}
                </span>
              </h4>
              <h4 class="text-primary" v-if="item.msg">
                <b-avatar :variant="'light-' + mainAlert.color" size="45">
                  <feather-icon size="21" icon="AlertTriangleIcon" />
                </b-avatar>
                <span
                  class="font-weight-bolder font-lg mt-1"
                  :class="'text-' + mainAlert.color"
                >
                  {{ item.msg }}
                </span>
              </h4>
            </div>

            <div
              class="
                col-md-4 col-xl-4 col-sm-12 col-xs-12
                text-xl-right text-lg-right text-md-right
              "
            >
              <b-button
                v-if="mainAlert.button"
                :variant="mainAlert.button.variant"
                size="lg"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    mainAlert.button.action,
                    mainAlert.button.text,
                    mainAlert.button.question
                  )
                "
                >{{ mainAlert.button.title }}</b-button
              >

              <b-button
                v-if="mainAlert.button1"
                :variant="mainAlert.button1.variant"
                class="ml-2"
                size="lg"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    mainAlert.button1.action,
                    mainAlert.button1.text,
                    mainAlert.button1.question
                  )
                "
                >{{ mainAlert.button1.title }}</b-button
              >
            </div>
          </div>
        </div>
      </div> -->

      <div class="">
        <b-col class="alert alert-danger p-2 text-black-50">
          <b-row class="m-0">
            <b-col cols="12" class="pt-1 pb-1">
              <b-button variant="primary" size="lg" class="mr-1 mb-1">
                <FeatherIcon icon="StarIcon" size="17"></FeatherIcon>
                {{ $t("flash_sale") }}
              </b-button>
              <b-button variant="primary" size="lg" class="mr-1 mb-1">
                <FeatherIcon icon="BookIcon" size="17"></FeatherIcon>
                {{ $t("assign_to_book") }}
              </b-button>
              <b-button variant="primary" size="lg" class="mr-1 mb-1">
                <FeatherIcon icon="DollarSignIcon" size="17"></FeatherIcon>
                {{ $t("list_for_sale_to_public_market") }}
              </b-button>

            </b-col>
            <b-col cols="12" class="pb-1">
              <b-button
                v-if="item.status != 1"
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'remove',
                    $t('remove'),
                    $t('are_you_sure_you_want_to_remove_this_invoice')
                  )
                "
                >{{ $t("remove") }}</b-button
              >
              <b-button
                v-if="item.status != 1"
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'archive',
                    $t('archive'),
                    $t('are_you_sure_you_want_to_archive_this_invoice')
                  )
                "
              >
                {{ $t("archive") }}</b-button
              >
              <b-button
                v-if="item.status != 11"
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="changeStatus('settled')"
                >{{ $t("settled") }}
              </b-button>
              <b-button
                v-if="item.status == 1"
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'status',
                    $t('status'),
                    $t('are_you_sure_you_want_set_to_inactive')
                  )
                "
                >{{ $t("set_inactive") }}
              </b-button>
              <b-button
                v-else
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'status',
                    $t('status'),
                    $t('are_you_sure_you_want_set_to_active')
                  )
                "
                >{{ $t("set_active") }}</b-button
              >
              <b-button
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                @click="showAduitTrial"
                :disabled="item.scorings.length < 1"
              >
                {{ $t("audit_trial") }}</b-button
              >
              <b-button
                v-if="item.status != 1"
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'score',
                    $t('score_invoice'),
                    $t('are_you_sure')
                  )
                "
                >{{ $t("score_invoice") }}</b-button
              >
              <b-button
                v-if="item.status != 1"
                variant="primary"
                size="lg"
                class="mr-1 mb-1"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'pending',
                    $t('set_pending'),
                    $t('are_you_sure')
                  )
                "
                >{{ $t("set_pending") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </div>

      <div class="mt-2" v-if="item.scorings.length > 0">
        <b-col class="">
          <div align="end" class="font-weight-bolder text--gray">
            {{ $t("Admin Additional Information") }}
          </div>
          <b-row>
            <b-table
              :fields="fields"
              :items="[item.scorings[item.scorings.length - 1]]"
              striped
              hover
              responsive=""
            >
              <template #cell(score)="">
                <span>{{
                  invoice.weight_score ? invoice.weight_score : ""
                }}</span>
              </template>
              <template #cell(terms)="">
                <span>{{ invoice.terms }}</span>
              </template>
              <template #cell(created_at)="data">
                <span>{{ data.item.created_at | formatDate }}</span>
              </template>
              <template #cell(seller)="">
                <span v-if="invoice.company">
                  <span> {{ invoice.company.name }} </span
                  ><span v-html="'('"> </span>
                  <span v-if="invoice.seller_score">
                    {{ invoice.seller_score }}
                  </span>
                  <span v-html="')'"> </span>
                </span>
              </template>
              <template #cell(debtor)="">
                <span v-if="invoice.debtor">
                  <span> {{ invoice.debtor.name }} </span
                  ><span v-html="'('"> </span>
                  <span v-if="invoice.debtor_score">
                    {{ invoice.debtor_score }}
                  </span>
                  <span v-html="')'"> </span>
                </span>
              </template>
            </b-table>
          </b-row>
        </b-col>
      </div>
      <div class="mt-2" v-if="item.scorings.length > 0">
        <b-col class="alert alert-success p-2 text-black-50">
          <b-row class="m-0" v-if="item.status == 1">
            <b-col>
              <b-avatar variant="light-success" size="45">
                <feather-icon size="25" icon="AlertTriangleIcon" />
              </b-avatar>
              <span class="mb-25 font-weight-bolder">
                {{ $t("congrats") }}
                {{ $t("your_invoice_is_eligible_for_sale") }}
              </span>
            </b-col>
          </b-row>
          <b-row class="m-0 col-md-12">
            <b-col md="4" class="font-weight-bolder text-black-50 p-1">
              <h5>
                <span class="font-weight-bolder">{{ $t("rating") }}</span>
                <feather-icon icon="HelpCircleIcon" size="18" /> <br />
                  {{ invoice.scorings[0].rating }}
              </h5>
            </b-col>
            <b-col md="4" class="font-weight-bolder text-black-50 p-1">
              <h5>
                <span class="font-weight-bolder">{{
                  $t("discount_in_%")
                }}</span>
                <feather-icon icon="HelpCircleIcon" size="18" />
                <br />
                {{ invoice.scorings[0].discount_in_percentage }} %
              </h5>
            </b-col>
            <b-col md="4" class="font-weight-bolder text-black-50 p-1">
              <h5>
                <span class="font-weight-bolder"> {{ $t("type") }}</span>
                <feather-icon icon="HelpCircleIcon" size="18" /><br />
                <span
                  v-html="item.resource == 0 ? 'Non Recourse' : 'Recourse'"
                ></span>
              </h5>
            </b-col>

            <b-col md="4" class="font-weight-bold text-black-50 p-1">
              <h5>
                <span class="font-weight-bolder">{{
                  $t("effective_value")
                }}</span>
                <feather-icon icon="HelpCircleIcon" size="18" /> <br />
                {{ item.scorings[0].amount_with_discount | formatAmount }}
              </h5>
            </b-col>
            <b-col md="4" class="font-weight-bold text-black-50 p-1">
              <h5>
                <span class="font-weight-bolder"> {{ $t("discount") }}</span>
                <feather-icon icon="HelpCircleIcon" size="18" /><br />
                {{ item.scorings[0].discount_from_amount | formatAmount }}
              </h5>
            </b-col>
            <b-col md="4" class="font-weight-bold text-black-50 p-1">
              <h5>
                <span class="font-weight-bolder">
                  {{ $t("proof_of_ownership") }}</span
                >

                <feather-icon icon="HelpCircleIcon" size="18" /><br />

                <span v-if="item.silent == 0" v-html="'Required'">
                  {{ $t("required") }}</span
                >
                <span v-else-if="item.silent == 1" v-html="'Not Needed'">
                  {{ $t("not_needed") }}</span
                >
              </h5>
            </b-col>
          </b-row>
        </b-col>
      </div>

      <b-form>
        <div class="mt-4">
          <b-row m-0 p-0 v-if="!load" class="mr-1">
            <b-col sm="12" xl="8" md="8" lg="8">
              <b-form-group
                v-if="showAutoSuggest"
                id="deptor"
                :label="$t('deptor') + ':'"
                label-for="deptor"
                class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="debtor"
                  rules="required"
                >
                  <b-form-select
                    disabled
                    :options="companies"
                    value-field="id"
                    text-field="name"
                    v-model="item.issuer_id"
                  >
                  </b-form-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-else
                id="deptor"
                :label="$t('deptor') + ':'"
                label-for="deptor"
                class="font-weight-bolder"
              >
                <vue-autosuggest
                  :suggestions="filteredOptions"
                  :limit="10"
                  v-model="item.company_name"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: 'form-control',
                  }"
                  @selected="onSelected($event, item)"
                  @keyup="searchForCompaniesName(item)"
                >
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item"
                      >{{ suggestion.item }}
                    </span>
                  </template>
                </vue-autosuggest>
                <b-button
                  variant="primary"
                  v-if="showHint"
                  class="mt-1"
                  disabled
                >
                  <b-spinner small type="grow"></b-spinner>
                  {{ $t("loading") }}...
                </b-button>
                <div
                  class="alert alert-danger p-1 mt-1"
                  v-if="companyMessage.length > 0"
                >
                  {{ companyMessage }}
                </div>
              </b-form-group>
            </b-col>

            <b-col sm="12" xl="6" md="6" lg="6">
              <b-form-group
                id="amount"
                class="font-weight-bolder"
                :label="$t('invoice_amount') + ':'"
                label-for="amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules=""
                >
                  <input
                    @focusout="parsedAmount1($event, item.amount)"
                    id="amount-input"
                    v-model="item.amount"
                    placeholder="00'000.00"
                    class="form-control"
                  />

                  <small class="text-danger" v-if="numbersAmountError">{{
                    numbersAmountError
                  }}</small>
                  <br v-if="numbersAmountError" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" xl="6" md="6" lg="6">
              <b-form-group
                id="issue_date"
                :label="$t('issue_date') + ':'"
                label-for="issue_date"
                class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="issue_date"
                  rules="required"
                >
                  <b-form-datepicker
                    no-flip
                    :max="disabledDates"
                    id="issue_date"
                    :placeholder="$t('choose_a_date')"
                    v-model="item.issue_date"
                    @input="changeDueDate(item)"
                    :state="errors.length > 0 ? false : null"
                    required
                    :locale="
                      $store.state.app && $store.state.app.language == 'de'
                        ? 'de'
                        : 'en'
                    "
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" xl="6" md="6" lg="6">
              <b-form-group
                id="payment_date"
                :label="$t('payment_term_in_days') + ':'"
                label-for="payment_date"
                class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="payment_date"
                  rules="required"
                >
                  <b-form-input
                    id="payment_date"
                    type="number"
                    @input="changeDueDate(item)"
                    v-model="item.terms"
                    required
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="errorDueDate.length > 0">{{
                    errorDueDate
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" xl="6" md="6" lg="6">
              <b-form-group
                id="due_date"
                class="font-weight-bolder"
                :label="$t('due_date') + ' (' + $t('calculated') + ') :'"
                label-for="due_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="due_date"
                  rules="required"
                >
                  <b-form-datepicker
                    no-flip
                    id="due_date"
                    :placeholder="$t('choose_a_date')"
                    v-model="item.due_date"
                    disabled
                    :locale="
                      $store.state.app && $store.state.app.language == 'de'
                        ? 'de'
                        : 'en'
                    "
                    required
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12" xl="6" md="6" lg="6">
              <b-form-group
                class="font-weight-bolder"
                id="invoice_num"
                :label="$t('invoice_number') + ':'"
                label-for="due_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="invoice_num"
                  rules="required"
                >
                  <b-form-input
                    id="invoice_num"
                    v-model="item.invoice_number"
                    required
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              xl="6"
              md="6"
              lg="6"
              align="end"
              class="mt-2"
              v-if="showUpdateButton"
            >
              <b-button
                variant="primary"
                :disabled="disabledSumbit"
                @click="editInvoice()"
              >
                {{ $t("update_invoice_data") }}</b-button
              >
            </b-col>
          </b-row>
          <b-row v-else cols="12" xl="6" md="6" lg="6">
            <img src="/loader.svg" />
          </b-row>
        </div>
      </b-form>
      <b-row class="mt-2">
        <b-col class="row blue-jumbotron row mt-1 ml-1 mr-1 pt-2 pb-2">
          <b-col md="12" class="pt-0">
            <b-form-checkbox
              v-model="silentFactoring"
              name="check-button"
              @input="changeInvoiceFact('silent', silentFactoring)"
              switch
            >
              {{ $t("silent_factoring") }}
              <feather-icon icon="HelpCircleIcon" size="18" />
            </b-form-checkbox>
          </b-col>
          <b-col md="12" class="pb-0 mb-0 pt-1">
            <b-form-group class="d-flex" v-slot="{}">
              <b-form-radio-group
                v-model="recourse"
                :options="options"
                class="mb-1"
                @input="changeInvoiceFact('recourse', recourse)"
                value-field="value"
                text-field="name"
                disabled-field="notEnabled"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="pt-0">
            <b-form-checkbox
              @input="changeInvoiceFact('flash_sale', flashSale)"
              name="check-button"
              disabled
              switch
            >
              {{ $t("flash_sale") }}
              <feather-icon icon="HelpCircleIcon" size="18" />
            </b-form-checkbox>
          </b-col>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="row blue-jumbotron row mt-1 ml-1 mr-1 pt-2 pb-2">
          <b-col md="12" class="pb-0 mb-0">
            <div class="text-decoration-underline">
              {{ $t("diese_AGB_gelten") }}
            </div>

            <ul>
              <li>{{ $t("diese_AGB_gelten_A") }}</li>
              <li>{{ $t("diese_AGB_gelten_B") }}</li>
              <li>{{ $t("diese_AGB_gelten_C") }}</li>
            </ul>
            <div class="row col-md-12">
              <b-checkbox v-model="agreeTerms"></b-checkbox>
              <label for="">{{ $t("i_have_read_and_agree_with") }}</label>
            </div>
          </b-col>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col md="12" align="end" class="mr-1">
          <b-row class="mt-1 justify-content-end" align="end">
            <b-button
              variant="primary"
              class="mr-1 mb-1"
              v-b-modal.approveModal
              @click="
                changeModalInfo(
                  'remove',
                  $t('remove'),
                  $t('are_you_sure_you_want_to_remove_this_invoice')
                )
              "
              >{{ $t("remove") }}</b-button
            >
            <b-button
              variant="primary"
              class="mr-1 mb-1"
              v-b-modal.approveModal
              @click="
                changeModalInfo(
                  'archive',
                  $t('archive'),
                  $t('are_you_sure_you_want_to_archive_this_invoice')
                )
              "
              >{{ $t("archive") }}</b-button
            >
            <b-button variant="primary" disabled class="mr-2 mb-1"
              >{{ $t("sell_invoice") }} 1</b-button
            >
          </b-row>
        </b-col>
      </b-row>
      <div
        class=""
        v-if="item.fractions_bought && item.fractions_bought.length > 0"
      >
        <div class="col-md-12 mt-1 pl-0 mr-1 pt-2 pb-2">
          <div align="end" class="font-weight-bolder text--gray">
            {{ $t("fractions_bought") }}
          </div>

          <b-table
            striped
            id="fraction-table"
            :items="item.fractions_bought"
            :fields="fractionFields"
            responsive
          >
            <template #head()="scope">
              <div class="text-nowrap">{{ $t(scope.label) }}</div>
            </template>
            <template #cell(currency)="">
              <span>CHF</span>
            </template>
            <template #cell(buyer)="data">
              <span v-if="data.item.company && data.item.company.name">{{
                data.item.company.name
              }}</span>
              <span
                v-else-if="data.item.company.user && data.item.company.user[0]"
                >{{ data.item.company.user[0].name }}
                {{ data.item.company.user[0].last_name }}</span
              >
            </template>

            <template #cell(fraction)="data">
              <span v-if="data.item.fraction"> {{ data.item.fraction }}% </span>
              <span v-else>-</span>
            </template>
            <template #cell(amount)="data">
              <span v-if="data.item.amount">
                {{ data.item.amount | formatAmount }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(discount)="data">
              <span v-if="data.item.discount">
                {{ data.item.discount | formatAmount }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(hive_fee)="data">
              <span v-if="data.item">
                {{ data.item.hive_fee | formatAmount }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(net_return)="data">
              <span v-if="data.item">
                {{ data.item.net_return | formatAmount }}
              </span>
              <span v-else>-</span>
            </template>

            <template #cell(created_at)="data">
              <span v-if="data.item.created_at">{{
                data.item.created_at | formatDate
              }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(custom)="data">
              <b-button
                size="sm"
                variant="danger"
                v-b-modal.approveModal
                @click="
                  changeModalInfo(
                    'cancel_fraction',
                    $t('cancel_fraction'),
                    $t('are_you_sure_you_want_to_cancel_this_fraction'),
                    data.item
                  )
                "
                class="text-end"
                >{{ $t("cancel") }}</b-button
              >
            </template>
            <template #cell(status)="data">
              <span v-if="data.value == 0">{{ $t("pending") }} </span>
              <span v-if="data.value == 1">{{ $t("approved") }}</span>
              <span v-if="data.value == 2">{{ $t("declined") }}</span>
            </template>
          </b-table>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col class="row blue-jumbotron row mt-1 ml-1 mr-1 pt-2 pb-2">
          <b-row class="justify-content-between col-md-12">
            <b-col class="">
              <h6>{{ $t("associated_files") }}</h6>
            </b-col>
          </b-row>
          <b-col class="col-md-12">
            <div class="mt-1 row col-md-12">
              <h5 class="mr-10">
                <b-link class="web">
                  <feather-icon
                    icon="PaperclipIcon"
                    class="pointer"
                    size="22"
                    @click="readInvoice(invoice)"
                /></b-link>
                <b-link class="mobile">
                  <feather-icon
                    icon="PaperclipIcon"
                    class="pointer"
                    size="22"
                    @click="downloadInvoice(invoice)"
                /></b-link>
                {{ invoice.title }}
              </h5>
              <div class="ml-10" style="margin-left: 40px">
                {{ invoice.created_at | formatDate }}
              </div>
            </div>
          </b-col>

          <b-col
            v-if="item.associate_files && item.associate_files.length > 0"
            class="col-md-12"
          >
            <div
              v-for="(item, index) in item.associate_files"
              :key="index"
              class="mt-1 row col-md-12"
            >
              <h5 class="mr-10">
                <b-link class="web">
                  <feather-icon
                    icon="PaperclipIcon"
                    class="pointer"
                    size="22"
                    @click="readFile(item)"
                /></b-link>

                <b-link class="mobile">
                  <feather-icon
                    icon="PaperclipIcon"
                    class="pointer"
                    size="22"
                    @click="downloadFile(item)"
                /></b-link>
                {{ item.title }}
              </h5>
              <div class="ml-10" style="margin-left: 40px">
                {{ item.created_at | formatDate }}
              </div>
            </div>
          </b-col>
        </b-col>
      </b-row>
      <div class="mt-3">
        <div class="blue-jumbotron col-md-12 mt-1 pt-2 pb-2">
          <b-row class="col-md-12">
            <b-col
              class="col-md-12 text-center pointer"
              @click="triggerFileInput"
            >
              <feather-icon icon="DownloadIcon" size="22" />
              {{ $t("upload_a_file_or_drag_files_here") }}
            </b-col>
            <div style="display: none">
              <b-form-file
                @change="uploadFile($event)"
                v-model="associated"
                ref="fileInput1"
                class="mt-1 mb-2 col-md-3"
                label="Upload Associated Invoice"
              ></b-form-file>
            </div>
          </b-row>
          <b-row class="text-center"> </b-row>
        </div>
      </div>

      <b-modal hide-footer v-model="uploadFileModal" centered>
        <div>
          <h4 class="font-weight-bolder">
            {{ $t("assign_new_document_to_invoice") }}
          </h4>
        </div>
        <div>
          <h5 class="mt-1 mb-1">
            <feather-icon icon="PaperclipIcon" class="pointer" size="20" />
            {{ $t("file") }}
            :
            {{
              uploadedInvoce && uploadedInvoce.file
                ? uploadedInvoce.file.name
                : ""
            }}
          </h5>
        </div>
        <div class="mt-1">
          {{ $t("file_description") }}:
          <b-form-input v-model="fileDesc"></b-form-input>
        </div>
        <div class="mt-1">
          {{ $t("type") }}:
          <b-form-select
            v-model="fileType"
            :options="fileTypeOptions"
          ></b-form-select>
        </div>
        <div class="col-md-12 pr-0 mt-3" align="end">
          <b-button variant="primary" class="mt-1" @click="storeData">
            {{ $t("upload") }}</b-button
          >
        </div>
      </b-modal>
    </div>

    <AuditTrial v-else :item="item" @showDetails="showDetails" />
    <ApproveModal :textContent="modalText" :title="modalTitle" />

    <AddNewCompnayModal
      :showDialog="addNewComp"
      :invoice="item"
      :company="companyZefixData"
      @showSettings="showSettings"
      @closeAddCompModal="closeAddCompModal"
    ></AddNewCompnayModal>

    <MainContactModal
      :showDialog="MainContact"
      :invoice="item"
      :company="companyZefixDataMainContact"
      @showSettings="showSettings"
      @closeMainContactModal="closeMainContactModal"
    ></MainContactModal>
  </b-card>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import fileDownload from "js-file-download";
import ApproveModal from "@/components/Common/ApproveModal";
import AuditTrial from "@/components/Invoice/AuditTrial.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import InvoiceStatus from "@/components/Invoice/InvoiceStatus";
import i18n from "@/libs/i18n";
import { VueAutosuggest } from "vue-autosuggest";
import AddNewCompnayModal from "../../Invoice/AddNewCompanyModal.vue";
import MainContactModal from "../../Invoice/MainContactModal.vue";

import Cleave from "vue-cleave-component";

export default {
  data() {
    return {
      recourse: "",
      flashSale: "",
      agreeTerms: "",
      load: true,
      silentFactoring: false,
      uploadFileModal: false,
      associated: null,
      uploadedInvoce: {
        file: null,
        id: "",
      },
      fields: [
        {
          label: this.$t("score"),
          key: "score",
          variant: "danger",
          thClass: "bg-light-danger text-dark",
        },
        {
          label: this.$t("days_to_due_refactor"),
          key: "terms",
          variant: "danger",
          thClass: "bg-light-danger text-dark",
        },
        {
          label: this.$t("date_of_scoring"),
          key: "created_at",
          variant: "danger",
          thClass: "bg-light-danger text-dark",
        },
        {
          label: this.$t("seller"),
          key: "seller",
          variant: "danger",
          thClass: "bg-light-danger text-dark",
        },
        {
          label: this.$t("debtor"),
          key: "debtor",
          variant: "danger",
          thClass: "bg-light-danger text-dark",
        },
      ],
      fileTypeOptions: [
        {
          text: i18n.t("proof_of_ownership"),
          value: "Proof Of Ownership",
        },
        {
          text: i18n.t("notice_of_assignment"),
          value: "Notice Of Assignment",
        },
        {
          text: i18n.t("other"),
          value: "Other",
        },
      ],
      fractionFields: [
        {
          label: "date",
          align: "start",
          key: "created_at",
          variant: "success",
          thClass: "bg-light-success text-dark",
        },
        {
          label: "invoice_id",
          align: "start",
          key: "invoice_id",
          variant: "success",
          thClass: "bg-light-success text-dark",
        },

        {
          label: "buyer",
          align: "start",
          key: "buyer",
          variant: "success",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },

        {
          label: "currency",
          align: "start",
          variant: "success",
          key: "currency",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },
        {
          label: "amount",
          align: "start",
          variant: "success",
          key: "amount",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },
        {
          label: "fraction",
          align: "start",
          key: "fraction",
          variant: "success",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },

        {
          label: "discount",
          align: "start",
          variant: "success",
          key: "discount",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },
        {
          label: "hive_fee",
          align: "start",
          variant: "success",
          key: "hive_fee",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },
        {
          label: "net_return",
          align: "start",
          key: "net_return",
          sortable: false,
          variant: "success",
          thClass: "bg-light-success text-dark",
        },
        {
          label: "status",
          key: "status",
          customSlot: true,
          sortable: false,
          variant: "success",
          thClass: "bg-light-success text-dark",
        },
        {
          label: "",
          key: "custom",
          align: "end",
          customSlot: true,
          sortable: false,
          variant: "success",
          thClass: "bg-light-success text-right",
          tdClass: "text-right",
        },
      ],
      fileType: "",
      fileDesc: "",
      showUpdateButton: false,
      options: [
        {
          name: i18n.t("Non-Recourse"),
          item: "Non-Recourse",
          value: 0,
        },
        {
          name: i18n.t("Recourse"),
          item: "Recourse",
          value: 1,
        },
      ],
      companies: [],
      approveModalAction: null,
      modalText: "",
      modalTitle: "",
      auditTrial: false,
      addNewComp: false,
      companyZefixData: null,
      invoiceModal: null,
      filteredOptions: [],
      showAutoSuggest: false,
      disabledSumbit: false,
      errorDueDate: "",
      numbersAmountError: "",
      companyMessage: "",
      showHint: false,
      MainContact: false,
      companyZefixDataMainContact: null,
      fraction: null,
      amountOptions: {
        customDelimiter: {
          delimiters: ["'", "."],
          blocks: [2, 3, 2],
          uppercase: true,
        },
      },
    };
  },
  components: {
    StatisticCardHorizontal,
    ValidationProvider,
    ValidationObserver,
    ApproveModal,
    AuditTrial,
    InvoiceStatus,
    VueAutosuggest,
    AddNewCompnayModal,
    Cleave,
    MainContactModal,
  },
  props: ["invoice"],
  computed: {
    mainAlert() {
      let mainAlert = {
        button: {
          title: "",
          action: "",
          text: "",
          question: "",
          variant: "",
        },
        button1: {
          title: "",
          action: "",
          text: "",
          question: "",
          variant: "",
        },
        message: "",
        showUpdate: false,
        showAlert: false,
        color: "secondary",
        icon: "",
      };
      this.showUpdateButton = false;
      if (this.item.status == 0) {
        mainAlert.button = "";
        mainAlert.button1 = "";
        mainAlert.message = "invoice_status_pending_message";
        mainAlert.showUpdate = true;
        this.showUpdateButton = true;
        mainAlert.showAlert = true;
        mainAlert.color = "secondary";
        mainAlert.icon = "LoaderIcon";
      } else if (this.item.status == 1) {
        mainAlert.button = "";
        mainAlert.button1 = "";
        mainAlert.message = "";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = false;
      } else if (this.item.status == 2) {
        mainAlert.button = {
          action: "pending",
          text: "status",
          question: "are_you_sure_you_want_start_from_begining",
          title: "start_process_from_beginning ",
          variant: "danger",
        };

        mainAlert.button1 = "";
        mainAlert.message = "invoice_status_decline_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;

        mainAlert.color = "danger";
        mainAlert.icon = "XCircleIcon";
      } else if (this.item.status == 3) {
        mainAlert.button = {
          action: "score",
          text: "score_invoice",
          question: $t("are_you_sure"),
          title: "score",
          variant: "warning",
        };
        mainAlert.button1 = "";
        mainAlert.message = "invoice_status_scroing_required_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.color = "warning";
        mainAlert.icon = "FlagIcon";
      } else if (this.item.status == 4) {
        mainAlert.button1 = {
          action: "scoreDecline",
          text: "status",
          question: "are_you_sure_you_want_to_decline",
          title: "decline",
          variant: "danger",
        };
        mainAlert.button = {
          action: "scoreApprove",
          text: "status",
          question: "are_you_sure_you_want_to_approve",
          title: "approve",
          variant: "success",
        };
        mainAlert.message = "invoice_status_scroing_approval_required_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "AlertOctagonIcon";
        mainAlert.color = "secondary";
      } else if (this.item.status == 5) {
        mainAlert.button = "";
        mainAlert.button1 = "";
        mainAlert.message =
          "invoice_status_proof_of_ownership_required_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "FilePlusIcon";
        mainAlert.color = "primary";
      } else if (this.item.status == 6) {
        mainAlert.button = {
          action: "approve",
          text: "status",
          question: "are_you_sure_you_want_approve_for_sale",
          title: "approve_for_sale",
          variant: "success",
        };
        mainAlert.button1 = "";
        mainAlert.message = " invoice_status_ready_for_sale_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;

        mainAlert.icon = "CheckCircleIcon";
        mainAlert.color = "success";
      } else if (this.item.status == 7) {
        mainAlert.button = {
          action: "pending",
          text: "status",
          question: "are_you_sure_you_want_start_from_begining",
          title: "start_process_from_beginning ",
          variant: "primary",
        };
        mainAlert.button1 = "";
        mainAlert.message = "invoice_status_decline_from_scoring_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "RefreshCcwIcon";
        mainAlert.color = "secondary";
      } else if (this.item.status == 8) {
        mainAlert.button = {
          action: "proofOfOwnershipInProgress",
          text: "status",
          question: "are_you_sure",
          title: "proof_of_ownership_approval",
          variant: "primary",
        };
        mainAlert.button1 = "";
        mainAlert.message = "proof_of_ownership_in_progress_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "FilePlusIcon";
        mainAlert.color = "primary";
      } else if (this.item.status == 9) {
        mainAlert.button = "";
        mainAlert.button1 = "";
        mainAlert.message = "partially_bought_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "FilePlusIcon";
        mainAlert.color = "secondary";
      } else if (this.item.status == 10) {
        mainAlert.button = {
          action: "settled",
          text: "status",
          question: "are_you_sure?",
          title: "settled",
          variant: "primary",
        };
        mainAlert.button1 = "";
        mainAlert.message = "fully_bought_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "FilePlusIcon";
        mainAlert.color = "primary";
      } else if (this.item.status == 11) {
        mainAlert.button = "";
        mainAlert.button1 = "";
        mainAlert.message = "invoice_payback_message";
        mainAlert.showUpdate = false;
        mainAlert.showAlert = true;
        mainAlert.icon = "FilePlusIcon";
        mainAlert.color = "success";
      }
      return mainAlert;
    },
    item() {
      return this.invoice;
    },
    disabledDates() {
      var date = new Date();
      var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return today;
    },
  },
  created() {
    this.getCompanies();
    if (this.item) {
      this.load = false;
      this.parsedAmount(this.item.amount);
      if (this.item.flash_sale == 0) {
        this.flashSale = false;
      } else {
        this.flashSale = true;
      }
      this.flashSale = false;

      if (this.item.silent == 0) {
        this.silentFactoring = false;
      } else {
        this.silentFactoring = true;
      }

      if (this.item.recourse == 0) {
        this.recourse = 0;
      } else {
        this.recourse = 1;
      }
      if (this.item.issuer_id) {
        this.showAutoSuggest = true;
      }
      if (this.item && this.item.debtor) {
        this.item.company_name = this.item.debtor.name;
      }
    }
  },
  methods: {
    readInvoice(item) {
      this.$http
        .post("/invoice/admin/read/" + item.id, {
          responseType: "json",
        })
        .then((response) => {
          this.$store.commit("app/SET_FILE_TYPE", response.data.value.ext);
          this.$store.commit("app/SET_FILE_BASE64", response.data.value.b64);

          window.open("/read-files", "_blank");
        });
    },

    readFile(item) {
      this.$http
        .post("/invoice/admin/associate/read/" + item.id, {
          responseType: "json",
        })
        .then((response) => {
          this.$store.commit("app/SET_FILE_TYPE", response.data.value.ext);
          this.$store.commit("app/SET_FILE_BASE64", response.data.value.b64);

          window.open("/read-files", "_blank");
        });
    },
    closeMainContactModal(data) {
      if (data != null) {
        this.invoice.issuer_id = data[0].company_id;
      } else {
        this.invoice.company_name = "";
      }
      this.$bvModal.hide("MainContact");
    },
    parsedAmount(val) {
      setTimeout(() => {
        if (val == this.item.amount && val) {
          if (this.item.amount.includes("'")) {
            this.item.amount = this.item.amount.replace(/'/gi, "");
          }
          this.item.amount = Number(this.item.amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&'");
        }
      }, 1000);
    },

    parsedAmount1($event, val) {
      this.numbersAmountError = "";
      let regex = /^[0-9']*(.)?[0-9]*$/;

      if (regex.test(val)) {
        setTimeout(() => {
          if (val == this.item.amount && val) {
            if (this.item.amount.includes("'")) {
              this.item.amount = this.item.amount.replace(/'/gi, "");
            }
            this.item.amount = Number(this.item.amount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&'");
          }
        }, 1000);
      } else {
        this.numbersAmountError = this.$t("only_numbers_allowed");
        $event.preventDefault();
        return;
      }
    },

    closeAddCompModal(data) {
      //this.getCompanies();
      if (data != null) {
        this.invoice.company_name = data[0].name;
        this.invoice.uid = data[0].uid;
        this.invoice.issuer_id = data[0].id;
      } else {
        this.invoice.company_name = "";
      }
      this.$bvModal.hide("AddCompany");
    },
    showSettings() {
      this.addNewComp = false;
      this.openModal = false;
    },
    onSelected(val, item) {
      // value.company_name =
      item.company_name = val.item;

      this.getCompanyDataFromZefix(item);
      // this.successMessage = false;
    },
    getCompanyDataFromZefix(item) {
      const self = this;
      if (typeof this.companies[item.company_name] != "undefined") {
        this.$http
          .post("/get-company-data-from-zefix", {
            search: this.companies[item.company_name],
          })
          .then((res) => {
            if (res) {
              item.company_uid = res.data.value.uid;
              let data = {
                search: item.company_uid,
                invoice_id: item.id,
              };

              this.$http.post("/invoice/getCompany", data).then((res) => {
                if (res) {
                  if (res.data.value && res.data.value.from == "Database") {
                    item.issuer_id = res.data.value.company.id;
                  } else if (
                    res.data.value &&
                    res.data.value.from == "ContactPerson"
                  ) {
                    this.showMainContactModal(item, res.data.value.company);
                  } else {
                    this.showModal(item, res.data.value.company);
                  }
                }
              });
            }
          });
      }
    },
    showModal(item, company) {
      this.companyZefixData = company;
      this.$bvModal.show("AddCompany");
    },
    showMainContactModal(item, company) {
      this.companyZefixDataMainContact = company;
      this.$bvModal.show("MainContact");
    },
    searchForCompaniesName(item) {
      clearTimeout(this.timeout);
      clearTimeout(this.timeout);
      const self = this;
      this.companyMessage = "";
      this.timeout = setTimeout(function () {
        if (item.company_name.length > 2) {
          self.showHint = true;
          self.companies = [];
          self.filteredOptions = [];
          self.$http
            .post("/get-companies-name-from-zefix", {
              search: item.company_name,
            })
            .then((res) => {
              self.successMessage = false;
              self.filteredOptions.push({
                data: Object.keys(res.data.value),
              });
              self.showHint = false;
              if (res.data.value.length < 1) {
                self.companyMessage =
                  "no_results_with_the_information_you_entered_please_try_again";
              }
              self.companies = res.data.value;
            });
        } else {
          self.companies = [];
          self.filteredOptions = [];
          self.showHint = false;
        }
      }, 1000);
    },

    changeDueDate(item) {
      if (this.item.issue_date && this.item.terms) {
        let newDate = new Date(this.item.issue_date);

        let term = Number(this.item.terms);
        let parsed = new Date(this.item.issue_date);

        let compareDate = "";
        newDate.setDate(parsed.getDate() + term);
        compareDate = newDate;

        this.item.due_date = newDate.toISOString().split("T")[0];

        let max = 0;
        if (term <= 30) {
          max = 10;
        } else if (term <= 45) {
          max = 12;
        } else if (term <= 60) {
          max = 15;
        } else if (term <= 75) {
          max = 17;
        } else if (term > 75) {
          max = 20;
        }
        compareDate.setDate(compareDate.getDate() - max);
        var today = new Date();
        if (compareDate > today) {
          this.errorDueDate = "";
          this.disabledSumbit = false;
        } else {
          this.errorDueDate = this.$t("due_date_issue_text");
          this.disabledSumbit = true;
        }
      }
    },
    changeInvoiceFact(type, value) {
      let data = {
        data: value,
      };
      this.$http
        .post(`/invoice/admin/change/${type}/${this.invoice.id}`, data)
        .then((res) => {
          if (res) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("success"),
                icon: "SuccessIcon",
                variant: "success",
              },
            });
          }
        });
    },
    editInvoice() {
      if (this.numbersAmountError.length < 1) {
        let data = {
          debtor_id: this.invoice.company_id,
          issuer_id: this.invoice.issuer_id,
          issue_date: this.invoice.issue_date,
          due_date: this.invoice.due_date,
          terms: Number(this.invoice.terms),
          amount: this.invoice.amount.replace(/'/gi, ""),
          invoice_number: this.invoice.invoice_number,
          status: this.invoice.status,
        };

        this.$http
          .post(`/invoice/admin/update/${this.invoice.id}`, data)
          .then((res) => {
            if (res) {
              this.showSuccessMessage(i18n.t("invoice_updated_successfully"));
            }
          });
      }
    },
    triggerFileInput() {
      this.$refs.fileInput1.$refs.input.click();
    },
    storeData() {
      let formData = new FormData();
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      formData.append("pdf", this.associatedEvent.target.files[0]);
      this.$http
        .post(
          `/invoice/admin/associate/upload/${this.invoice.id}`,
          formData,
          config
        )
        .then((res) => {
          if (res) {
            let data = {
              description: this.fileDesc,
              type: this.fileType,
            };
            this.$http
              .post(`/invoice/admin/associateData/${res.data.value.id}`, data)
              .then((response) => {
                if (response) {
                  this.uploadFileModal = false;
                  this.showSuccessMessage("File Upload Successfully");
                  this.associated = null;
                  this.fileType = "";
                  this.fileDesc = "";
                }
              });
          }
        });
    },
    uploadFile(event) {
      this.fileType = "";
      this.fileDesc = "";
      this.uploadedInvoce.file = event.target.files[0];
      this.associatedEvent = event;
      this.uploadFileModal = true;
    },
    showDetails() {
      this.auditTrial = false;
    },
    showAduitTrial() {
      this.auditTrial = true;
    },
    scoreInvoice() {
      this.$http.post(`/invoice/admin/score/${this.invoice.id}`).then((res) => {
        if (res) {
          this.uploadFileModal = false;

          if (res.data.message) {
            this.showSuccessMessage(res.data.message);
          }
          this.fileType = "";
          this.fileDesc = "";
        }
      });
    },
    changeModalInfo(action, title, text, item) {
      this.approveModalAction = action;
      this.modalText = text;
      this.modalTitle = title;
      this.fraction = item;
      console.log(this.fraction);
    },

    callApproveModalAction() {
      if (this.approveModalAction == "archive") {
        this.changeStatus("archive");
      } else if (this.approveModalAction == "remove") {
        this.changeStatus("remove");
      } else if (this.approveModalAction == "approve") {
        this.changeStatus("approve");
      } else if (this.approveModalAction == "status") {
        this.changeStatus("status");
      } else if (this.approveModalAction == "pending") {
        this.changeStatus("pending");
      } else if (this.approveModalAction == "scoreDecline") {
        this.changeStatus("scoreDecline");
      } else if (this.approveModalAction == "scoreApprove") {
        this.changeStatus("scoreApprove");
      } else if (this.approveModalAction == "proofOfOwnership") {
        this.changeStatus("proofOfOwnership");
      } else if (this.approveModalAction == "settled") {
        this.changeStatus("settled");
      } else if (this.approveModalAction == "score") {
        this.scoreInvoice();
      } else if (this.approveModalAction == "cancel_fraction") {
        this.cancelFraction();
      }
    },
    cancelFraction() {
      this.$http
        .post(`/invoice/admin/fraction/${this.fraction.id}/cancel`)
        .then((res) => {
          console.log(res);
        });
    },
    showSuccessMessage(message, type) {
      let text = this.$t(message);
      this.$http
        .post("/invoice/admin/show/" + this.invoice.id, {})
        .then((response) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: text,
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          if (response.data.status == 200) {
            this.invoice = response.data.value;
            this.parsedAmount(this.invoice.amount);
            this.invoice.company_name = response.data.value.debtor.name;
          }

          this.load = false;
          if (type == "remove") {
            this.$emit("showTable");
            // this.$router.replace("/invoices");
          }
        })
        .catch((error) => {
          this.load = false;
        });
    },
    downloadInvoice(item) {
      this.$http
        .post(
          "/invoice/admin/download/" + item.id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          let parsed = response.data.type.split("/");
          let str = "doc." + parsed[1];
          fileDownload(response.data, item.title);
        })
        .catch((error) => {});
    },

    downloadFile(item) {
      this.$http
        .post(
          "/invoice/admin/associate/download/" + item.id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          let parsed = response.data.type.split("/");
          let str = "doc." + parsed[1];
          fileDownload(response.data, str);
        })
        .catch((error) => {});
    },

    changeStatus(type) {
      let status = 0;
      let message = "";
      let route = "";
      if (type == "status") {
        if (this.item.status == 1) {
          status = 0;
        } else {
          status = 1;
        }
        message = "status_changed_successfully";
      }

      if (type == "archive") {
        status = 2;
        message = "invoice_has_been_successfully_archived";
      }

      if (type == "remove") {
        status = 99;
        route = "remove";
        message = "invoice_has_been_successfully_removed";
      }
      if (type == "approve") {
        status = 1;
        message = "the_invoce_has_been_approved";
      }
      if (type == "pending") {
        status = 0;
        message = "success";
      }
      if (type == "scoreApprove") {
        status = 5;
        message = "success";
      }
      if (type == "proofOfOwnership") {
        status = 8;
        message = "success";
      }
      if (type == "proofOfOwnershipInProgress") {
        status = 6;
        message = "success";
      }
      if (type == "scoreDecline") {
        status = 7;
        message = "success";
      }
      if (type == "settled") {
        status = 11;
        message = "success";
      }

      this.$http
        .post(`/invoice/admin/changeStatus/${this.invoice.id}`, {
          status: status,
        })
        .then((res) => {
          if (res) {
            if (res.data.message) {
              this.showSuccessMessage(this.$t(res.data.message), route);
            } else if (res.data.value.msg) {
              this.showSuccessMessage(this.$t(res.data.value.msg), route);
            } else {
              this.showSuccessMessage(this.$t(message), route);
            }
            window.location.reload();
          }
        });
    },

    getCompanies() {
      this.$http.post("/company/admin/allList", {}).then((response) => {
        if (response.data.status == 200) {
          this.companies = response.data.value;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>