var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('h2',{staticClass:"text-blue font-weight-bold mt-1 col-md-12"},[_vm._v(" "+_vm._s(_vm.$t("main_contact_person"))+" ")])])]),_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[(_vm.item && _vm.item.main_contacts_debtor && _vm.item.main_contacts_debtor.length>0)?_c('b-form',_vm._l((_vm.item.main_contacts_debtor),function(item,index){return _c('b-card',{key:index},[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-contact-gender-group","label":_vm.$t('salutation') + ':',"label-for":"main-contact-gender"}},[_c('b-form-select',{attrs:{"id":"main-contact-gender","disabled":"","options":[
                  {
                    text: _vm.$t('select_one'),
                    value: null,
                    disabled: true,
                  },
                  { text: _vm.$t('mr'), value: '0' },
                  { text: _vm.$t('mrs'), value: '1' } ],"required":""},model:{value:(item.gender),callback:function ($$v) {_vm.$set(item, "gender", $$v)},expression:"item.gender"}})],1)],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-contact-email-group","label":_vm.$t('company_email_address') + ':',"label-for":"main-contact-email"}},[_c('validation-provider',{attrs:{"name":"Number of Debtors","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-email","required":"","disabled":"","state":errors.length > 0 ? false : null},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-contact-first-name-group","label":_vm.$t('first_name') + ':',"label-for":"main-contact-first-name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-first-name","required":"","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(item.first_name),callback:function ($$v) {_vm.$set(item, "first_name", $$v)},expression:"item.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"tel-group","label":_vm.$t('mobile') + ':',"label-for":"tel"}},[_c('validation-provider',{attrs:{"name":"tel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"id":"phoneCountries"}},[_c('b-form-select',{attrs:{"disabled":true,"options":_vm.phoneCountriesList},model:{value:(_vm.phoneCountryCode),callback:function ($$v) {_vm.phoneCountryCode=$$v},expression:"phoneCountryCode"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"main-contact-tel","disabled":"","state":errors.length > 0 ? false : null},on:{"input":_vm.checkPhoneFormat},model:{value:(item.tel),callback:function ($$v) {_vm.$set(item, "tel", $$v)},expression:"item.tel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation))]):_vm._e()]}}],null,true)})],1)],1)]),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-contact-last-name-group","label":_vm.$t('last_name') + ':',"label-for":"main-contact-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-last-name","required":"","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(item.last_name),callback:function ($$v) {_vm.$set(item, "last_name", $$v)},expression:"item.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-contact-position-group","label":_vm.$t('position_in_the_company') + ':',"label-for":"main-contact-position"}},[_c('validation-provider',{attrs:{"name":"Position in the Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-position","required":"","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(item.position),callback:function ($$v) {_vm.$set(item, "position", $$v)},expression:"item.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])],1)}),1):(_vm.item && _vm.item.main_contact && _vm.item.main_contact.id>0)?_c('b-form',[_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-person-gender-group","label":_vm.$t('salutation') + ':',"label-for":"main-contact-gender"}},[_c('b-form-select',{attrs:{"id":"main-person-gender","disabled":"","options":[
                  {
                    text: _vm.$t('select_one'),
                    value: null,
                    disabled: true,
                  },
                  { text: _vm.$t('mr'), value: '0' },
                  { text: _vm.$t('mrs'), value: '1' } ],"required":""},model:{value:(_vm.item.main_contact.gender),callback:function ($$v) {_vm.$set(_vm.item.main_contact, "gender", $$v)},expression:"item.main_contact.gender"}})],1)],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-person-email-group","label":_vm.$t('company_email_address') + ':',"label-for":"main-contact-email"}},[_c('validation-provider',{attrs:{"name":"Number of Debtors","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-person-email","required":"","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.item.main_contact.email),callback:function ($$v) {_vm.$set(_vm.item.main_contact, "email", $$v)},expression:"item.main_contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-person-first-name-group","label":_vm.$t('first_name') + ':',"label-for":"main-contact-first-name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-person-first-name","required":"","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.item.main_contact.name),callback:function ($$v) {_vm.$set(_vm.item.main_contact, "name", $$v)},expression:"item.main_contact.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"person-tel-group","label":_vm.$t('mobile') + ':',"label-for":"tel"}},[_c('validation-provider',{attrs:{"name":"tel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"id":"phoneCountries"}},[_c('b-form-select',{attrs:{"options":_vm.phoneCountriesList,"disabled":true},model:{value:(_vm.phoneCountryCode),callback:function ($$v) {_vm.phoneCountryCode=$$v},expression:"phoneCountryCode"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"main-person-tel","disabled":"","state":errors.length > 0 ? false : null},on:{"input":_vm.checkPhoneFormat},model:{value:(_vm.item.main_contact.tel),callback:function ($$v) {_vm.$set(_vm.item.main_contact, "tel", $$v)},expression:"item.main_contact.tel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation))]):_vm._e()]}}])})],1)],1)]),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-person-last-name-group","label":_vm.$t('last_name') + ':',"label-for":"main-contact-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-person-last-name","required":"","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.item.main_contact.last_name),callback:function ($$v) {_vm.$set(_vm.item.main_contact, "last_name", $$v)},expression:"item.main_contact.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-form-group',{attrs:{"id":"main-person-position-group","label":_vm.$t('position_in_the_company') + ':',"label-for":"main-contact-position"}},[_c('validation-provider',{attrs:{"name":"Position in the Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-person-position","required":"","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.item.main_contact.position),callback:function ($$v) {_vm.$set(_vm.item.main_contact, "position", $$v)},expression:"item.main_contact.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }