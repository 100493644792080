<template>
  <div>
    <b-alert :variant="alertVariantStatus()" show class="p-2" v-show="showBox">
      <p>{{ boxTitle }}</p>
      <b-row>
        <b-col class="col-xl-9 col-lg-9 col-md-12">
          <b-form-textarea
              v-model="reason"
              style="height: 95px"
              placeholder="Reason"
          ></b-form-textarea>
        </b-col>
        <b-col class="col-xl-3 col-lg-3 col-md-12 text-right">
          <b-button
              variant="danger"
              style="width: 100%"
              @click="updateCompanyStatus('decline')"
              class="mr-1"
          ><feather-icon icon="TrashIcon"></feather-icon> Decline</b-button
          >
          <br />
          <br />
          <b-button
              variant="success"
              style="width: 100%"
              @click="updateCompanyStatus('approve')"
          ><feather-icon icon="CheckIcon"></feather-icon> Approve</b-button
          >
        </b-col>
      </b-row>
    </b-alert>
    <b-row>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <h2 class="text-blue font-weight-bold mt-1 col-md-12">
          {{ $t("Settings") }}
        </h2>

        <p class="col-md-12">
          {{ $t("edit_information") }}
        </p>
      </div>
      <b-col class="col-md-6 aling-end" align="end">
        <h4 class="light-blue">
          {{ $t("status") }}:
          <span pill variant="light-success" v-if="company.status == 1">
            {{ $t("active") }}
          </span>
          <span pill variant="light-secondary" v-else>
            {{ $t("inactive") }}
          </span>
          <br />

          <b-button
              @click="disableCompany()"
              variant="danger"
              class="mr-1 mt-1"
              size="sm"
          >{{ $t("disable") }}</b-button
          >
          <b-button
              @click="enableCompany()"
              variant="success mt-1"
              size="sm"
          >{{ $t("enable") }}</b-button
          >
        </h4>
      </b-col>
      <b-col v-if="company.role == 'seller'" class="col-12 d-flex pb-0 mb-0" >

       <span class="col-12 d-flex " >
        <span>
          <b-form-select
              v-model="selected_level"
              :options="levels"
              value-field="item"
              text-field="name"
              size="sm" class="mr-1"
          ></b-form-select>
    </span>
    <br>
    <span>
          <b-button
              @click="updateLevel()"
              variant="primary"
              size="sm"
              class="ml-1"
          >{{ $t("update_level") }}</b-button
          >
        </span>
        </span>

      </b-col>

    </b-row>
    <validation-observer ref="companyRegisterValidation" name="RegisterCompany">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-card>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-md-12">
              <b-form-group
                  id="domicile-country-group"
                  :label="$t('domicile_country') + ':'"
                  label-for="domicile-country"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                >
                  <b-form-select
                      id="domicile-country"
                      v-model="country_id"
                      :options="countries"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  id="company-name-group"
                  :label="$t('company_name') + ':'"
                  label-for="company-name"
              >
                <b-input-group>
                  <template #append>
                    <b-button
                        @click="getCompanyDataFromZefix()"
                        v-if="companies[company_name] != null"
                    >
                      <feather-icon icon="DownloadIcon" /> Get Data
                    </b-button>
                  </template>
                  <b-form-input
                      autocomplete="off"
                      @keyup="searchForCompaniesName()"
                      id="company-name"
                      v-model="company_name"
                      required
                      list="companies-list"
                      :disabled="disableInputs"
                  ></b-form-input>
                </b-input-group>
                <div class="mt-2">
                  {{ $t("company_uid") }}: "{{ company_uid }}"
                </div>
                <datalist id="companies-list">
                  <option v-for="(company, index) in companies" :key="index">
                    {{ index }}
                  </option>
                </datalist>
              </b-form-group>
              <b-form-group
                  id="company-description-group"
                  :label="$t('Description') + ':'"
                  label-for="company-description"
              >
                <b-input-group>
                  <b-form-textarea
                      id="company-description"
                      v-model="companyDescriptionValue"
                      placeholder="Description"
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-md-12 p-0">
              <div class="col-md-12">{{ $t("roles") }}:</div>
              <div class="col-md-12 font-weight-bolder pt-0">
                <span v-if="company && company.role == 'debtor'">
                  <feather-icon
                      size="20"
                      icon="CodesandboxIcon"
                      class="text-primary"
                  ></feather-icon>
                  {{ $t("debtor") }}
                </span>
                <span
                    v-else-if="
                    (company && company.role == null) ||
                    company.role == 'seller'
                  "
                >
                  <feather-icon
                      size="20"
                      icon="CodesandboxIcon"
                      class="text-secondary"
                  ></feather-icon>
                  {{ $t("seller") }}
                </span>
                <span v-if="company && company.buyer == 1">
                  <feather-icon
                      size="20"
                      icon="CodesandboxIcon"
                      class="text-success"
                      variant="success"
                  ></feather-icon>
                  {{ $t("buyer") }}
                </span>
              </div>
              <div

                  class="col-xl-12 col-lg-12"
                  style="margin-top: 30px"
              >
                <b-form-group
                    id="language_of_correspondence'"
                    :label="$t('language_of_correspondence') + ':'"
                    label-for="language_of_correspondence'"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-select
                        :options="[
                        { text: $t('german'), value: 'de' },
                        { text: $t('english'), value: 'en' },
                      ]"
                        @change="changeLanguage"
                        id="language_of_correspondence"
                        :disabled="disableInputs"
                        v-model="language"
                        required
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
              </div>

              <img src="/loader.svg" v-if="showLoader" />
              <!-- {{ JSON.stringify(company_zefix_data) }} -->
            </div>
            <div class="col-xl-3 col-md-12">
              <Market :buyer="buyer"></Market>
            </div>
          </b-row>
        </b-card>
        <b-card>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-name-field-group"
                  :label="$t('company_name') + ':'"
                  label-for="company-name-field"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    rules="required"
                >
                  <b-form-input
                      id="company-name-field"
                      v-model="company_name"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-address-group"
                  :label="$t('company_address') + ':'"
                  label-for="company-address"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company Address"
                    rules="required"
                >
                  <b-form-input
                      id="company-address"
                      v-model="company_address"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">

              <b-form-group
                  id="company-address-nr-group"
                  :label="$t('company_address_nr') + ':'"
                  label-for="company-address-nr"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company Address Nr"
                    rules="required"
                >
                  <b-form-input
                      id="company-address-nr"
                      v-model="company_address_nr"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-uid-group"
                  :label="$t('company_uid') + ' ' +
                 '(CHE-397.517.219 > CHE397517219):'"
                  label-for="company-uid"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company UID"
                    rules="required"
                >
                  <b-form-input
                      id="company-uid"
                      v-model="company_uid"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-zip-group"
                  :label="$t('zip') + ':'"
                  label-for="company-zip"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company Zip"
                    rules="required"
                >
                  <b-form-input
                      id="company-zip"
                      v-model="company_zip"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-city-group"
                  :label="$t('city') + ':'"
                  label-for="company-city"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company City"
                    rules="required"
                >
                  <b-form-input
                      id="company-city"
                      v-model="company_city"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-legal-form-group"
                  :label="$t('legal_form') + ':'"
                  label-for="company-legal-form"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Company Legal Form"
                    rules="required"
                >
                  <b-form-select
                      id="company-legal-form"
                      v-model="company_legal_form"
                      :options="[
                          { value: 0, text: $t('sole_proprietorship')},
                          { value: 1, text: $t('general_partnership')},
                          { value: 2, text: $t('limited_partnership')},
                          { value: 3, text: $t('corporation')},
                          { value: 4, text: $t('limited_liability_company')},
                          { value: 5, text: $t('cooperative')},
                          { value: 6, text: $t('cantonal_public_company')},
                          { value: 7, text: $t('association')}
                         ]"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" v-for="option in currencies">
              <b-form-group
                  id="balance-group"
                  :label="$t('balance') + ' ' + company_balances[option.id].name + ' :'"
                  label-for="company_balance"
              >
                  <b-form-input
                      id="company_balance"
                      v-model="company_balances[option.id].balance"
                      required
                      :disabled="disableInputs"
                  ></b-form-input>
                  <!--</b-form-select>-->
              </b-form-group>
            </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <b-button @click="transaction_modal = true" variant="outline-secondary    " class="mt-2 w-50">{{ $t('change_amounts_transaction') }}</b-button>
              </div>

              <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                  <!--<b-form-group-->
                      <!--id="transactions-fee-group"-->
                      <!--:label="$t('transactions_fee') + ':'"-->
                      <!--label-for="company-transactions-fee"-->
                  <!--&gt;-->
                      <!--<validation-provider-->
                          <!--#default="{ errors }"-->
                          <!--name="Transactions fee"-->
                          <!--rules="required"-->
                      <!--&gt;-->
                          <!--<b-form-input-->
                              <!--id="company-transactions-fee"-->
                              <!--v-model="company_transactions_fee"-->
                              <!--required-->
                              <!--:state="errors.length > 0 ? false : null"-->
                              <!--:disabled="disableInputs"-->
                          <!--&gt;</b-form-input>-->
                          <!--<small class="text-danger">{{ errors[0] }}</small>-->
                      <!--</validation-provider>-->
                  <!--</b-form-group>-->
              <!--</div>-->

            <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
              <!--<b-form-group-->
                <!--id="company-website-group"-->
                <!--:label="$t('website') + ':'"-->
                <!--label-for="company-website"-->
              <!--&gt;-->
                <!--<b-form-input-->
                  <!--id="company-website"-->
                  <!--v-model="company_website"-->
                  <!--required-->
                <!--&gt;</b-form-input>-->
              <!--</b-form-group>-->
            <!--</div>-->
          </b-row>
        </b-card>
        <h5 v-if="role != 'debtor'">{{ $t("financial_data") }}</h5>
        <b-card v-if="role != 'debtor'">
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-established-at-group"
                  :label="$t('established_since') + ':'"
                  label-for="company-established-at-field"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Established since"
                    rules="required"
                >
                  <b-form-datepicker
                      no-flip
                      id="company-established-at-field"
                      v-model="company_established_at"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      required
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-number-of-employees-group"
                  :label="$t('number_of_employees') + ':'"
                  label-for="company-number-of-employees"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Number of employees"
                    rules="required"
                >
                  <b-form-input
                      id="company-number-of-employees"
                      v-model="number_of_employees"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :label="$t('annual_turnover') + ':'"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Annual turnover"
                    rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend id="selectedCurrency">

                      <b-form-select v-model="selected_currency" :options="currenciesOptions" :disabled="disableInputs">

                      </b-form-select>
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="annual_turnover"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                    >
                    </b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-intent-to-sell-group"
                  :label="$t('how_much_do_you_intend_to_sell') + '?'"
                  label-for="company-intent-to-sell"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Intend to sell"
                    rules="required"
                >
                  <b-form-select
                      id="company-intent-to-sell"
                      v-model="intent_to_sell"
                      :options="intent_to_sell_values"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="company-number-of-debtors-group"
                  :label="$t('total_number_of_debtors') + ':'"
                  label-for="company-number-of-debtors"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Number of Debtors"
                    rules="required"
                >
                  <b-form-select
                      id="company-number-of-debtors"
                      v-model="number_of_debtors"
                      :options="number_of_debtors_values"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :label="$t('open_debt_enforcement_against_you') + '?'"
                  v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                    id="company-debt-enforcement"
                    v-model="debt_enforcement"
                    :options="debt_enforcement_values"
                    :aria-describedby="ariaDescribedby"
                    name="debt-enforcement"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </b-row>
        </b-card>
        <h5>{{ $t("main_contact_person") }}</h5>
        <b-card >
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="main-contact-gender-group"
                  :label="$t('salutation') + ':'"
                  label-for="main-contact-gender"
              >
                <b-form-select
                    id="main-contact-gender"
                    v-model="main_contact_gender"
                    :options="[
                    {
                      text: $t('select_one'),
                      value: null,
                      disabled: true,
                    },
                    { text: $t('mr'), value: '0' },
                    { text: $t('mrs'), value: '1' },
                  ]"
                    required
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="main-contact-email-group"
                  :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Number of Debtors"
                    rules="required|email"
                >
                  <b-form-input
                      id="main-contact-email"
                      v-model="main_contact_email"
                      required
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="main-contact-first-name-group"
                  :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="First name"
                    rules="required"
                >
                  <b-form-input
                      id="main-contact-first-name"
                      v-model="main_contact_first_name"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="tel-group"
                  :label="$t('mobile') + ':'"
                  label-for="tel"
              >
                <validation-provider
                    #default="{ errors }"
                    name="tel"
                    rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend id="phoneCountries">
                      <b-form-select
                          :options="phoneCountriesList"
                          v-model="phoneContries"
                      >
                      </b-form-select>
                    </b-input-group-prepend>
                    <b-form-input
                        id="main-contact-tel"
                        v-model="main_contact_tel"
                        @input="checkPhoneFormat"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                    >
                    </b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="phoneValidation">{{
                    phoneValidation
                    }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="main-contact-last-name-group"
                  :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                >
                  <b-form-input
                      id="main-contact-last-name"
                      v-model="main_contact_last_name"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="main-contact-position-group"
                  :label="$t('position_in_the_company') + ':'"
                  label-for="main-contact-position"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Position in the Company"
                    rules="required"
                >
                  <b-form-input
                      id="main-contact-position"
                      v-model="main_contact_position"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>


            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="signing-authority-type-group"
                  :label="$t('salutation') + ':'"
                  label-for="signing-authority-type"
              >
                <b-form-select
                    id="signing-authority-type"
                    v-model="main_contact_signing_type"
                    :options="[
                    {
                      text: $t('select_one'),
                      value: null,
                      disabled: true,
                    },
                    { text: $t('no_signatory'), value: '2' },
                    { text: $t('sole_signatory'), value: 0 },
                    { text: $t('collectively_by_two'), value: 1 },
                  ]"
                    required
                ></b-form-select>
              </b-form-group>
            </div>
          </b-row>
        </b-card>
        <h5 v-if="signators.length > 0">{{ $t("other_signers") }}</h5>
        <b-card
            style="background: #fefefe"
            v-for="(signator, index) in signators"
            :key="index"
        >

          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-gender-group'"
                  :label="$t('salutation') + ':'"
                  :label-for="index + 'signator-gender'"
              >
                <b-form-select
                    :id="index + 'signator-gender'"
                    v-model="signator.gender"
                    :options="[
                    {
                      text: $t('select_one'),
                      value: null,
                      disabled: true,
                    },
                    { text: $t('mr'), value: '0' },
                    { text: $t('mrs'), value: '1' },
                  ]"
                    required
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-email-group'"
                  :label="$t('company_email_address') + ':'"
                  :label-for="index + 'signator-email'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="index + ' Email'"
                    rules="required|email"
                >
                  <b-form-input
                      @change="validateEmail"
                      :id="index + 'signator-email'"
                      v-model="signator.email"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger" v-if="emailValidationsError">{{
                    emailValidationsError
                    }}</small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-first-name-group'"
                  :label="$t('first_name') + ':'"
                  :label-for="index + 'signator-first-name'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="index + ' First Name'"
                    rules="required"
                >
                  <b-form-input
                      :id="index + 'signator-first-name'"
                      v-model="signator.name"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-tel-group'"
                  :label="$t('mobile') + ':'"
                  :label-for="index + 'signator-tel'"
              >
                <b-input-group class="">
                  <b-input-group-prepend
                      class="phoneCountries"
                      id="phoneCountries"
                  >
                    <b-form-select
                        :options="phoneCountriesList"
                        v-model="signator.iso_code"
                    >
                    </b-form-select>
                  </b-input-group-prepend>
                  <b-form-input
                      :id="index + 'signator-tel'"
                      v-model="signator.tel"
                      @input="
                      checkPhoneFormatSignator(signator.tel, signator.iso_code)
                    "
                      class="form-control"
                  >
                  </b-form-input>
                </b-input-group>
                <span v-if="phoneSignatorValidation" class="text-danger">{{phoneSignatorValidation}}</span>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-last-name-group'"
                  :label="$t('last_name') + ':'"
                  :label-for="index + 'signator-last-name'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="index + ' Last Name'"
                    rules="required"
                >
                  <b-form-input
                      :id="index + 'signator-last-name'"
                      v-model="signator.last_name"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-position-group'"
                  :label="$t('position_in_the_company') + ':'"
                  :label-for="index + 'signator-position'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="index + ' Position'"
                    rules="required"
                >
                  <b-form-input
                      :id="index + 'signator-position'"
                      v-model="signator.position"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  :id="index + 'signator-signing-authority-type-group'"
                  :label="$t('signing_authority_type') + ':'"
                  :label-for="index + 'signator-signing-authority-type'"
              >
                <b-form-select
                    :id="index + 'signator-signing-authority-type'"
                    v-model="signator.signer.signing_authority_type"
                    :options="[
                    {
                      text: $t('select_one'),
                      value: null,
                      disabled: true,
                    },
                    { text: $t('sole_signatory'), value: '0' },
                    { text: $t('collectively_by_two'), value: '1' },
                  ]"
                    required
                ></b-form-select>
              </b-form-group>
            </div>
            <div
                class="
                col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12
                text-right
                pt-2
              "
            >
              <div class="text-left">
                <b-form-checkbox
                    value="1"
                    :id="index + 'signator-is-receiver'"
                    v-model="signator.signer.receivers"
                    required
                >This signer should receive emails</b-form-checkbox>
              </div>
              <b-button
                  variant="outline-danger"
                  @click="deleteSignator(index, signator)"
              >
                <feather-icon icon="DeleteIcon"></feather-icon>
                {{ $t("delete") }}
              </b-button>
            </div>
          </b-row>
        </b-card>
        <b-row m-0 p-0 v-if="signators.length < 5">
          <b-col cols="12" class="text-right">
            <b-button variant="outline-primary" @click="addNewSigner()">
              <feather-icon icon="PlusIcon"></feather-icon>
              {{ $t("add_signatory") }}
            </b-button>
          </b-col>
        </b-row>


          <b-card v-if="company.role == 'seller' && company.status == 1">
             <b-row m-0 p-0>
                 <h4 class="mb-3">{{ $t('fee_hive') }}</h4>
                 <b-table
                     sticky-header
                     :fields="feeHiveDueDaysFields"
                     :items="feeHiveDueDays"
                     responsive
                     style="height: 400px; overflow-y: auto"
                 >
                     <template #head()="scope">
                         <div class="text-nowrap">{{ $t(scope.label) }}</div>
                     </template>
                     <template #cell(terms_upper_border)="data">
                         <b-form-input
                             v-model="data.item.terms_upper_border"
                         ></b-form-input>
                     </template>
                     <template #cell(terms_lower_border)="data">
                         <b-form-input
                             v-model="data.item.terms_lower_border"
                         ></b-form-input>
                     </template>
                     <template #cell(hive_fee)="data">
                         <b-form-input v-model="data.item.hive_fee"></b-form-input>
                     </template>
                     <template #cell(position)="data">
                         <b-form-input v-model="data.item.position"></b-form-input>
                     </template>
                     <template #cell(action)="data">
                         <b-button variant="success" @click="submitTermsHiveFee(data.item)">{{
                             $t("save")
                             }}</b-button>
                     </template>
                 </b-table>
             </b-row>
         </b-card>


        <h5>
          {{ $t("company_bank_information") }}
        </h5>

        <b-card>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="iban-group"
                  :label="$t('iban') + ':'"
                  label-for="iban"
              >
                <validation-provider
                    #default="{ errors }"
                    name="iban"
                    rules="required"
                >
                  <b-form-input
                      id="iban"
                      v-model="iban"
                      required
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row m-0 p-0>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="bank_name"
                  :label="$t('bank_name') + ':'"
                  label-for="bank_name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Bank Name"
                    rules="required"
                >
                  <b-form-input
                      id="bank_name"
                      v-model="bank_name"
                      required
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <b-form-group
                  id="bank_address"
                  :label="$t('bank_address') + ':'"
                  label-for="bank_address"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Bank address"
                    rules="required"
                >
                  <b-form-input
                      id="bank_address"
                      v-model="bank_address"
                      required
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
        </b-card>
        <h5>{{ $t("fee_policy") }}</h5>
        <b-card>
          <b-row m-0 p-0>
            <b-col cols="12">
              <!-- terms-and-conditions -->
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="data_privacy"
                    rules="required"
                >
                  <b-form-checkbox
                      id="data_privacy"
                      v-model="data_privacy"
                      name="data_privacy"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  >
                    {{ $t("agree_with_policy_seller") }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div style="background: #efefef; padding: 10px" class="mt-1">
                  <p>
                    {{ $t("agree_with_policy_seller_subtitle") }}

                    <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <h5>{{ $t("legal_notice") }}</h5>
        <b-card>
          <b-row m-0 p-0>
            <b-col cols="12">
              <!-- terms-and-conditions -->
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="terms-and-conditions"
                    rules="required"
                >
                  <b-form-checkbox
                      id="terms-and-conditions"
                      v-model="terms_and_conditions"
                      name="terms-and-conditions"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                  >
                    {{ $t("agree_with_terms_&_conditions_seller_before_link") }}
                    <span class="web">
                      <router-link
                          to="/files/terms_de"
                          target="_blank"
                          v-if="$store.state.app.language == 'de'"
                      >{{
                          $t("agree_with_terms_&_conditions_seller_link")
                        }}</router-link
                      >
                      <router-link to="/files/terms" target="_blank" v-else>{{
                        $t("agree_with_terms_&_conditions_seller_link")
                      }}</router-link>
                    </span>
                    <span class="mobile">
                      <a
                          v-if="$store.state.app.language == 'de'"
                          href="/files/terms_de.pdf"
                          download=""
                      >{{
                          $t("agree_with_terms_&_conditions_seller_link")
                        }}</a
                      >
                      <a v-else href="/files/terms_eng.pdf" download="">{{
                        $t("agree_with_terms_&_conditions_seller_link")
                      }}</a>
                    </span>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div style="background: #efefef; padding: 10px" class="mt-1">
                  <p>
                    {{ $t("agree_with_terms_&_conditions_seller_subtitle") }}

                    <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                  </p>
                  <p class="col-md-12">
                    {{ $t("agree_with_terms_&_conditions_seller_1") }}

                    <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                  </p>
                  <p class="col-md-12">
                    {{ $t("agree_with_terms_&_conditions_seller_2") }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row m-0 p-0>
          <b-col cols="12" class="text-right">
            <b-button variant="primary" @click="saveCompanyInfo()">
              <feather-icon icon="SaveIcon"></feather-icon>
              {{ $t("edit_information") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal v-model="transaction_modal" @ok="submitTransaction()">
       <div class="mb-2">
           <label for="">currencies</label>
           <b-form-select
               :options="currencies"
               text-field="name"
               value-field="id"
               v-model="transactionData.currencyCode"
               @change="changeTransactionBalanceWithCurrency"
           >
           </b-form-select>
       </div>

        <div class="mb-2">
            <b-form-group
                id="transaction_balance_label"
                :label="$t('balance') + ':'"
                label-for="transaction_balance"
            >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('balance')"
                    rules="required"
                >
                    <b-form-input
                        type="number"
                        min="0"
                        id="transaction_balance"
                        v-model="transactionData.balance"
                        required
                        :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </div>

        <div class="mb-2">
            <b-form-group
                id="reason"
                :label="$t('reason') + ':'"
                label-for="reason_input"
            >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('reason')"
                    rules="required"
                >
                    <b-form-input
                        id="reason_input"
                        v-model="transactionData.reason"
                        required
                        :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </div>

    </b-modal>


  </div>

</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, email } from "@validations";
  import Market from "./Market.vue";
  import Cleave from "vue-cleave-component";
  import { phone } from "phone";

  import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BAlert,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  // import { mapGetters } from 'vuex/types/helpers';
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BAlert,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      ValidationProvider,
      ValidationObserver,
      Market,
      Cleave,
    },

    data() {
      return {
        transaction_modal: false,
        transactionData: {
            currencyCode: null,
            balance: null,
            reason: null
        },
        feeHiveDueDaysFields: [
          {
            label: "terms_lower_border",
            align: "start",
            key: "terms_lower_border",
          },
          {
            label: "terms_upper_border",
            align: "start",
            key: "terms_upper_border",
          },
          { label: "hive_fee", key: "hive_fee", align: "start" },
        ],
        feeHiveDueDays: null,
        currenciesOptions: [],
        company_balances: {},
        // company_transactions_fee: null,
        selected_level:null,
        levels: [
          { item: '1', name: 'Level 1' },
          { item: '2', name: 'Level 2' },
          { item: '3', name: 'Level 3',},
          { item: '4', name: 'Level 4' },
          { item: '5', name: 'Level 5',},
          { item: '6', name: 'Level 6' },
          { item: '7', name: 'Level 7' },
          { item: '8', name: 'Level 8' }
        ],
        currencies: null,
        reason: null,
        showLoader: false,
        disableInputs: false,
        language: "",
        country_id: 1,
        countries: [],
        company_id: this.$store.state.auth.user.company_id,
        company_name: null,
        companies: [],
        company_zefix_data: null,
        company_address: null,
        company_address_nr: null,
        company_uid: null,
        company_zip: null,
        company_city: null,
        company_legal_form: null,
        company_website: null,
        company_description: {
          'en': '',
          'de': ''
        },
        buyer: 0,
        legal_forms: [
          {
            value: 4,
            text: this.$t("Gesellschaft mit beschränkter Haftung"),
          },
          {
            value: 3,
            text: this.$t("Aktiengesellschaft"),
          },
          {
            value: 0,
            text: this.$t("Einzelunternehmen"),
          },
        ],
        phoneSignatorValidation: "",
        company_established_at: null,
        number_of_employees: 0,
        annual_turnover: 0,
        selected_currency: "",
        intent_to_sell: "1% - 10%",
        number_of_debtors: "1-10",
        debt_enforcement: "Yes",
        intent_to_sell_values: [
          "1% - 10%",
          "11% - 20%",
          "21% - 30%",
          "31% - 50%",
          "51% - 70%",
          "71% - 80%",
          "81% - 90%",
          "91% - 100%",
        ],
        number_of_debtors_values: [
          "1-10",
          "11-20",
          "21-50",
          "51-100",
          "101-200",
          "201-500",
          "500+",
        ],
        debt_enforcement_values: ["Yes", "No"],
        main_contact_id: 0,
        main_contact_gender: "",
        main_contact_email: "",
        main_contact_first_name: "",
        main_contact_last_name: "",
        main_contact_signing_type: "",
        emailValidationsError: "",
        main_contact_tel: null,
        main_contact_position: null,
        signing_authority_type: "Collectively by two",
        signing_authority_type_values: [
          this.$t("Sole signatory"),
          this.$t("Collectively by two"),
        ],
        phoneContries: "CH",
        phoneValidation: "",
        signators: [
          {
            iso_code: null,
            gender: "Mr",
            first_name: null,
            email: null,
            last_name: null,
            tel: null,
            position: null,
            signer: {
              signing_authority_type: "Collectively by two",
            },
          },
        ],
        role: "",
        terms_and_conditions: "",
        data_privacy: "",
        deletedSignators: "",
        company: {
          status: 99,
          score: 0,
        },
        bank_name: "",
        bank_address: "",
        iban: "",
        boxTitle: "",
        reference_number: "",
        phoneOptions: {
          phone: {
            delimiters: [" ", " ", " "],
            blocks: [2, 3, 2, 2],
          },
        },
        phoneCountriesList: [],
      };
    },
    name: "CompanyUpdateForm",

    created() {

      this.getCurrencies1();
      this.getDueDateTermsFees();

      // console.log(this.$route.params.id)
      if(this.$route.params.id){
        this.getData();
      }

      // if(this.company_description[this.$store.state.app.language]) {
      //   company_description[this.$store.state.app.language]
      // } else {
      //
      // }

    },

    props: ["makeCall"],

    computed: {
      // companyDescriptionValue() {
      //   return this.company_description[this.$store.state.app.language] ?? this.company_description['en'];
      // },

      companyDescriptionValue: {
        get() {
          return this.company_description[this.$store.state.app.language] ?? this.company_description['en'];
        },
        set(value) {
          this.company_description[this.$store.state.app.language] = value;
        }
      },

      showBox() {
        let dis = false;
        if (this.company.tasks && this.company.tasks.length > 0) {
          let company_review_task = this.company.tasks.find(
              (element) => element.name == "Add a company (Review Company Details)"
          );
          let perform_scoring_2 = this.company.tasks.find(
              (element) => element.name == "Perform Scoring 2"
          );
          if (
              typeof company_review_task != "undefined" &&
              company_review_task.status == 0
          ) {
            this.boxTitle = company_review_task.name;
            dis = true;
          }
          if (
              typeof perform_scoring_2 != "undefined" &&
              perform_scoring_2.status == 0
          ) {
            this.boxTitle = perform_scoring_2.name;
            dis = true;
          }
        }
        return dis;
      },
      disEnable() {
        let dis = true;

        if (
            this.company.scoring &&
            this.company.scoring.length > 0 &&
            (this.company.scoring[0].decision == "APPROVE" ||
                this.company.scoring[0].decision == "YELLOW")
        ) {
          dis = false;
        }
        return dis;
      },
      //    ...mapGetters(['user']),
    },

    watch: {
      makeCall(newValue, oldValue) {
        if (newValue !== oldValue && this.makeCall) {
          this.getData();
        }
      },
    },

    methods: {
      changeTransactionBalanceWithCurrency() {
        const currency = this.company_balances[this.transactionData.currencyCode];
        this.transactionData.balance = currency.balance
      },
      submitTransaction() {
        if(this.transactionData.balance < 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:"you can't set balance value less than 0",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
          return
        }
        // console.log(this.transactionData)
        this.$http.post(`/company/admin/change-balance-with-transaction/${this.$route.params.id}`, this.transactionData).then((response) => {
          if (response.data.status == 200) {
            this.feeHiveDueDays = response.data.value

            this.$toast({
              component: ToastificationContent,
              props: {
                title:"transaction successfully created",
                icon: "SuccessIcon",
                variant: "success",
              },
            });

            this.getData()

          }
        });
      },

      getDueDateTermsFees() {
        this.$http.post(`/company/admin/get-due-date-terms-fees/${this.$route.params.id}`).then((response) => {
          if (response.data.status == 200) {
            this.feeHiveDueDays = response.data.value
          }
        });
      },
      getCurrencies1() {
        this.$http.post("/currency").then((response) => {
          if (response.data.status == 200) {

            response.data.value.map((item) => {
              this.currenciesOptions.push({
                value: item.id,
                text: item.name,
              })
            })

          }
        });
      },

      getCurrencies(id) {
        this.$http.post("/currency", { 'id': id }).then((response) => {
          if (response.data.status == 200) {
            this.currencies = response.data.value;
            this.initializeCompanyBalance();
          }
        });
      },

      initializeCompanyBalance() {
        this.currencies.forEach(currency => {
          this.$set(this.company_balances, currency.id, { id: currency.id, name: currency.name, balance: currency.balance });
        });
        this.transactionData.currencyCode = 1
        this.changeTransactionBalanceWithCurrency()
      },

      updateLevel(){
        this.$http.post(`/company/admin/update/level/${this.$route.params.id}`, { level: this.selected_level }).then((res) => {
          if (res) {
            if (res.data.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message
                      ? this.$t(res.data.message)
                      : this.$t("success"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              window.location.reload();
            }
            else{
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "ErrorIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
      },
      validateEmail(val) {
        this.emailValidationsError = "";
        this.$http.post("company/checkEmail", { email: val }).then((res) => {
          if (res) {
            if (res.data.value.email) {
              this.emailValidationsError = this.$t("email_already_exist");
            }
          }
        });
      },
      checkPhoneFormatSignator(val, code) {
        if (val) {
          let validation = phone(val, {
            country: code,
            validateMobilePrefix: false
          });
          if (validation && validation.isValid == false) {
            this.phoneSignatorValidation = `Phone for ${code} must be valid`;
          } else {
            this.phoneSignatorValidation = "";
          }
        }
      },
      alertVariantStatus() {
        if (this.company != null) {
          if (this.company.status == 1) {
            return "success";
          } else if (this.company.status == 2) {
            return "danger";
          } else {
            return "warning";
          }
        } else {
          return "secondary";
        }
      },
      checkPhoneFormat() {
        let validation = phone(this.main_contact_tel, {
          country: this.phoneContries,
          validateMobilePrefix: false
        });

        if (validation && validation.isValid == false) {
          this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
        } else {
          this.phoneValidation = "";
        }
      },

      getData() {
        this.$http
            .post(`/company/admin/show/${this.$route.params.id}`)
            .then((res) => {
              const default_language = {
                'en': '',
                'de': ''
              };
              if (res && res.data && res.data.value) {
                this.selected_level = res.data.value.level;
                let response = res.data.value;


                console.log(response);


                this.getCurrencies(response.id);

                this.company = response;
                this.company_name = response.name;
                this.company_address = response.address;
                this.company_address_nr = response.address_nr;
                this.company_uid = response.uid;
                this.company_zip = response.zip;
                this.company_city = response.city;
                this.country_id = response.country_id;
                // this.company_transactions_fee = response.transactions_fee
                let description = response.description;
                // this.company_description = typeof description === 'string' && description != '' ? JSON.parse(description) : description;
                this.company_description = description != '' && description != null ? (typeof description === 'string' ? JSON.parse(description) : description) : default_language;

                // this.company_website = response.website;
                this.main_contact_id =response.main_contact ? response.main_contact.id : '';
                if(!response.main_contact && response.main_contacts_debtor && response.main_contacts_debtor[0]) {
                  response.main_contact = response.main_contacts_debtor[0];
                  response.main_contact.name = response.main_contact.first_name;
                  this.main_contact_id =response.main_contact ? response.main_contact.user_id : '';
                }
                this.main_contact_gender = response.main_contact ? response.main_contact.gender : '';
                this.main_contact_first_name = response.main_contact ? response.main_contact.name : '';
                this.main_contact_last_name = response.main_contact ? response.main_contact.last_name : '';
                this.main_contact_position =response.main_contact ? response.main_contact.position : '';
                this.phoneContries = response.main_contact ? response.main_contact.iso_code : '';
                this.main_contact_tel =response.main_contact ? response.main_contact.tel : '';
                this.main_contact_signing_type = response.main_contact ? response.main_contact.signing_authority_type : '';
                this.language = response.language;
                this.signators = response.signators;
                this.data_privacy = response.data_privacy == 1 ? true : false;
                this.placeholder = response.placeholder;

                this.terms_and_conditions =
                    response.terms_and_conditions == 1 ? true : false;
                this.company_legal_form = response.legal_form;
                this.bank_name = response.bank_name;
                this.bank_address = response.bank_address;
                this.iban = response.iban;
                this.main_contact_email =response.main_contact ? response.main_contact.email : '';
                this.reference_number = response.reference_number;
                if (response.financial_data) {
                  this.selected_currency = parseInt(response.financial_data.capital_currency_id);
                  this.number_of_employees =
                      response.financial_data.number_of_employees;
                  this.annual_turnover = response.financial_data.annual_turnover;
                  this.company_established_at =
                      response.financial_data.established_since;
                  this.number_of_debtors =
                      response.financial_data.number_of_debtors;
                  this.debt_enforcement =
                      response.financial_data.debt_enforcement == 1 ? "Yes" : "No";
                  this.signing_authority_type =
                      response.main_contact_signing_authority_type;

                  console.log(this.signing_authority_type);

                  this.intent_to_sell = response.financial_data.intent_to_sell;
                }

                console.log(this.signing_authority_type);

                this.role = response.role;
                this.buyer = response.buyer;
              }
            });

        this.$http.post("/countries").then((res) => {
          res.data.value.map((item) => {
            this.phoneCountriesList.push({
              value: item.iso_code,
              text: `${item.iso_code} (${item.phone_code})`,
            });

            this.countries.push({
              value: item.id,
              text: item.name,
            });
          });
        });
      },
      updateCompanyStatus(status) {
        this.$http
            .post(
                `/company/admin/update/status/${this.$route.params.id}/${status}`,
                { reason: this.reason }
            )
            .then((res) => {
              this.$emit("updateData");
              this.getData();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(res.data.message),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
            });
      },
      enableCompany() {
        this.$http
            .post(`/company/admin/activate/${this.$route.params.id}`, {})
            .then((res) => {
              this.$emit("updateData", "company");
              this.getData();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message
                      ? this.$t(res.data.message)
                      : this.$t("Company updated successfully"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
            });
      },
      changeLanguage() {
        this.$http.post(
            "/company/admin/update/language/" + this.$route.params.id,
            {
              language: this.language,
            }
        );
      },
      disableCompany() {
        this.$http
            .post(
                `/company/admin/update/status/${this.$route.params.id}/decline`,
                {}
            )
            .then((res) => {
              this.company.status = 2;
              this.$emit("updateData", "company");

              this.getData();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message
                      ? this.$t(res.data.message)
                      : this.$t("Company updated successfully"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
            });
      },
      saveCompanyInfo() {
        // this.$refs.companyRegisterValidation.validate().then((success) => {
        //   if (
        //     success &&
        //     this.phoneValidation.length < 1 &&
        //     this.emailValidationsError.length < 1
        //   ) {
        this.disableInputs = true;


        const formSubmissionData = {
          company_balances: this.company_balances,
          capital_currency_id: this.selected_currency,
          company_id: this.company_id,
          company_name: this.company_name,
          company_address: this.company_address,
          company_address_nr: this.company_address_nr,
          company_uid: this.company_uid,
          company_zip: this.company_zip,
          company_city: this.company_city,
          company_country_id: this.country_id,
          // company_website: this.company_website,
          description: this.company_description,
          main_contact_id: this.main_contact_id,
          legal_form: this.company_legal_form,
          main_contact_person_gender: this.main_contact_gender,
          main_contact_first_name: this.main_contact_first_name,
          main_contact_last_name: this.main_contact_last_name,
          main_contact_signing_type: this.main_contact_signing_type,
          main_contact_email: this.main_contact_email,
          main_contact_position: this.main_contact_position,
          main_contact_tel: this.main_contact_tel,
          signing_authority_type: this.signing_authority_type,
          established_at: this.company_established_at,
          number_of_employees: this.number_of_employees,
          annual_turnover: this.annual_turnover,
          intent_to_sell: this.intent_to_sell,
          number_of_debtors: this.number_of_debtors,
          debt_enforcement: this.debt_enforcement,
          signers: this.signators.length,
          terms_and_conditions: this.terms_and_conditions,
          data_privacy: this.data_privacy,
          placeholder: 1,
          deleted_signators: this.deletedSignators,
          bank_name: this.bank_name,
          bank_address: this.bank_address,
          iban: this.iban,
          reference_number: this.reference_number,
          iso_code: this.phoneContries,
          // transactions_fee: this.company_transactions_fee
          due_date_fees: this.feeHiveDueDays
        };
        console.log(formSubmissionData);``
        let data = {};
        this.signators.map((item, i) => {
          const nr = i + 1;
          const signator = {
            ["signator_" + nr + "_id"]: item.id,
            ["signator_" + nr + "_gender"]: item.gender,
            ["signator_" + nr + "_first_name"]: item.name,
            ["signator_" + nr + "_last_name"]: item.last_name,
            ["signator_" + nr + "_email"]: item.email,
            ["signator_" + nr + "_tel"]: item.tel,
            ["signator_" + nr + "_position"]: item.position,
            ["signator_" + nr + "_iso_code"]: item.iso_code,
            ["signator_" + nr + "_receivers"]: item.signer.receivers,
            ["signator_" + nr + "_signing_authority_type"]:
            item.signer.signing_authority_type,
          };
          data = { ...data, ...signator };
        });
        data = { ...data, ...formSubmissionData };
        console.log(data);
        this.$http
            .post(`/company/admin/update/${this.$route.params.id}`, data)
            .then((res) => {
              this.$emit("updateData", "company");

              //console.log(this.selected_currency);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Company updated successfully"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
            });
        //   } else {
        //     const el = document.getElementsByClassName("is-invalid");
        //     if (el) {
        //       setTimeout(function () {
        //         el[0].scrollIntoView({ behavior: "smooth", block: "center" });
        //       }, 500);
        //     }
        //     this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: "Some fields are missing",
        //         icon: "ErrorIcon",
        //         variant: "danger",
        //       },
        //     });
        //   }
        //
        //   this.disableInputs = false;
        // });
      },
      deleteSignator(index, item) {
        this.signators.splice(index, 1);
        if (item.id) {
          this.deletedSignators += item.id + ",";
        }
      },
      searchForCompaniesName() {
        if (this.company_name.length > 2) {
          this.$http
              .post("/get-companies-name-from-zefix", { search: this.company_name })
              .then((res) => {
                this.companies = res.data.value;
              });
        }
      },
      getCompanyDataFromZefix() {
        this.showLoader = true;
        if (typeof this.companies[this.company_name] != "undefined") {
          this.$http
              .post("/get-company-data-from-zefix", {
                search: this.companies[this.company_name],
              })
              .then((res) => {
                this.company_zefix_data = res.data.value;
                this.company_uid = this.company_zefix_data.uid;
                this.company_address = this.company_zefix_data.address.street;
                this.company_address_nr =
                    this.company_zefix_data.address.houseNumber;
                this.company_city = this.company_zefix_data.address.city;
                this.company_zip = this.company_zefix_data.address.swissZipCode;
                this.company_legal_form = this.company_zefix_data.legalForm.name.de;
                this.company_established_at = this.company_zefix_data.sogcDate;
                this.showLoader = false;
              });
        }
      },
      addNewSigner() {
        if (this.signators.length < 5) {
          const signators = [...this.signators];
          signators.push({
            gender: "Mr",
            first_name: null,
            email: null,
            last_name: null,
            tel: null,
            position: null,
            iso_code: "CH",
            signer: {
              signing_authority_type: null,
            },
          });
          this.signators = signators;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("you_can_add_only_up_to_5_signatory"),
              icon: "ErrorIcon",
              variant: "error",
            },
          });
        }
      },
      onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.form));
      },
      onReset(event) {
        event.preventDefault();
        this.country_id = 0;
        this.countries = [];
      },
    },

  };
</script>

<style scoped>
</style>
