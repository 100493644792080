<template>
  <b-card>
    <div v-if="!details">
      <b-row class="">
        <b-col class="col-md-12">
          <h2
            class="
              text-blue
              font-weight-bold
              col-md-10 col-lg-10 col-xl-11
              mt-1
            "
          >
            {{ $t("invoices") }}
          </h2>
        </b-col>
      </b-row>
      <Table
        :body="{}"
        :updateData="makeCall"
        :url="url"
        :fields="fields"
        :showSearch="false"
        @updateProp="updateProp"
      >
        <template #cell(id)="data">
<!--          <b-link @click="showModal(data.item)" style="width:55px;" class="badge badge-light-primary">I-{{ data.item.id }} </b-link>-->
          <router-link :to="'/invoice/'+data.item.id" style="width:55px;" class="badge badge-light-primary">I-{{ data.item.id }} </router-link>
        </template>
        <template #cell(seller)="data">
          <span v-html="data.item.company ? data.item.company.name : '-'">
          </span>
        </template>
        <template #cell(debtor)="data">
          <span v-html="data.item.debtor ? data.item.debtor.name : '-'"></span>
        </template>
        <template #cell(currency)="data">
          <span v-html="data.item.currency ? data.item.currency.code : '-'">
          </span>
        </template>
        <template #cell(amount)="data">
          <span v-if="data.item.amount">
            {{ data.item.amount | formatAmount }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(invoice_number)="data">
          <span
            v-html="data.item.invoice_number ? data.item.invoice_number : '-'"
          >
          </span>
        </template>
        <template #cell(status)="data">
        <span class="text-secondary" v-if="data.value == 0"
          >{{ $t("open") }}
        </span>
        <span class="text-success" v-if="data.value == 1">{{
          $t("active_marketplace_offer")
        }}</span>
        <span class="text-secondary" v-if="data.value == 2">{{
          $t("rejected")
        }}</span>
        <span class="text-secondary" v-if="data.value == 3">{{
          $t("scoring_required")
        }}</span>
        <span class="text-secondary" v-if="data.value == 4">{{
          $t("scored")
        }}</span>
        <span class="text-secondary" v-if="data.value == 5">{{
          $t("debtor_confirmation_required")
        }}</span>
        <span class="text-secondary" v-if="data.value == 6">{{
          $t("debtor_confirmation_required")
        }}</span>
        <span class="text-secondary" v-if="data.value == 7">{{
          $t("rejected")
        }}</span>
        <span class="text-secondary" v-if="data.value == 8">{{
          $t("soon_goes_active")
        }}</span>
        <span class="text-secondary" v-if="data.value == 9">{{
          $t("in_review")
        }}</span>
        <span class="text-secondary" v-if="data.value == 10">{{
          $t("sold")
        }}</span>
           <span class="text-secondary" v-if="data.value == 11">{{
          $t("settled")
        }}</span>
      </template>
        <template #cell(custom)="data">
<!--          <b-button-->
<!--            class="mr-1 mt-1 mobile"-->
<!--            @click="downloadPdf(data.item.id)"-->
<!--            variant="outline-success"-->
<!--            size="sm"-->
<!--          >-->
<!--            <FeatherIcon icon="DownloadIcon"></FeatherIcon>-->
<!--          </b-button>-->
          <b-button
              v-b-tooltip.hover :title="$t('read_pdf')"
            class="mt-1 web"
            @click="readPdf(data.item.id, data.item)"
            variant="outline-success"
            size="sm"
          >
            <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
          </b-button>
        </template>
        <template #cell(silent)="data">
          <span v-if="data.value == 0">- </span>
          <span v-if="data.value == 1">{{ $t("yes") }}</span>
        </template>
        <template #cell(recourse)="data">
          <span v-if="data.value == 0">- </span>
          <span v-if="data.value == 1">{{ $t("yes") }}</span>
        </template>
        <template #cell(flash_sale)="data">
          <span v-if="data.value == 0">- </span>
          <span v-if="data.value == 1">{{ $t("yes") }}</span>
        </template>
      </Table>
    </div>
    <div v-else>
      <InvoiceDetails
        @showTable="showTable"
        :invoice="invoice"
      ></InvoiceDetails>
    </div>
  </b-card>
</template>
<script>
import Table from "@/components/Common/Table2.vue";
import InvoiceDetails from "./InvoiceDetails.vue";
import fileDownload from "js-file-download";
import { BButton } from 'bootstrap-vue'

export default {
  components: { BButton, Table, InvoiceDetails },
  data() {
    return {
      fields1: [
        { label: "title", align: "start", key: "title", sortable: false },
        { label: "", customSlot: false, key: "custom", class: "text-right" },
      ],
      fields: [
        { label: "reference", align: "start", key: "id", sortable: true },
        { label: "seller", align: "start", key: "seller", sortable: false },
        { label: "debtor", align: "start", key: "debtor", sortable: false },
        {
          label: "invoice_number",
          align: "start",
          key: "invoice_number",
          sortable: false,
        },
        { label: "currency", align: "start", key: "currency", sortable: false },
        {
          label: "invoice_amount",
          align: "start",
          key: "amount",
          sortable: false,
        },
        { label: "silent", align: "start", key: "silent", sortable: false },
        {
          tlabelext: "Recourse",
          align: "start",
          key: "recourse",
          sortable: false,
        },
        {
          label: "flash_sale",
          align: "start",
          key: "flash_sale",
          sortable: false,
        },
        { label: "status", key: "status", customSlot: true, sortable: false },
        {
          label: "title",
          align: "end",
          key: "custom",
          customSlot: true,
          sortable: false,
        },
      ],
      url: "",
      details: false,
      invoice: null,
      item: null,
      showPdfViwer: false,
      type: "",
      base64: "",
      source: null,
      src: "",
      embedType: "",
    };
  },
  props: ["makeCall"],

  created() {
    // this.showModal();
    if(this.$route.hash){
       const hash = this.$route.hash;
    const match = hash.match(/#invoices(\d+)/);
    if (match) {
      const invoiceNumber = parseInt(match[1], 10);
      const item = {
        id: invoiceNumber
      }
      this.showModal(item); 
    }
    }
    this.url = `/invoice/admin/${this.$route.params.id}/list`;
  },
  methods: {
    readPdf(id, item) {
      this.item = item;
      this.$store.commit("app/ON_SIDEBAR_CLICK", false);
      this.loadPdf = true;
      this.type = "";
      this.base64 = "";
      this.src = "";
      this.embedType = "";

      this.$http
        .post("/invoice/admin/read/" + id, {
          responseType: "json",
        })
        .then((response) => {
          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;
          if (this.type == "pdf") {
            this.showPdfViwer = false;
            this.src =
              "data:application/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "application/" + this.type;

            this.loadPdf = false;
            this.showPdfViwer = true;
          } else {
            this.showPdfViwer = false;
            this.src =
              "data:image/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "image/" + this.type;

            this.loadPdf = false;
            this.showPdfViwer = true;
          }

          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
        });
    },
    downloadPdf(id) {
      this.isLoading = true;
      this.$http
        .post(
          "/invoice/admin/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, "doc.pdf");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    showTable() {
      this.details = false;
    },
    showModal(item) {
      this.$http.post(`/invoice/admin/show/${item.id}`).then((res) => {
        this.invoice = res.data.value;
        this.details = true;
      });
    },
    updateProp() {},
  },
};
</script>
